import Button from "components/Semantic/Button";
import ButtonOutline from "components/Semantic/ButtonOutline";
import React, { FC } from "react";

interface DoubleButtonFooterProps {
  buttonTitle1: string;
  buttonTitle2: string;
  view: "web" | "mobile";
  onClick1: any;
  onClick2: any;
  isLoadingSave?: boolean;
  isLoadingNext?: boolean;
  hideSave: boolean;
  hideNext: boolean;
}

const DoubleButtonFooter: FC<DoubleButtonFooterProps> = ({ buttonTitle1, buttonTitle2, view, onClick1, onClick2, isLoadingSave, isLoadingNext, hideSave, hideNext }) => {
  return (
    <div className={`fixed md:relative bottom-0 z-10 bg-white  w-full rounded-t-lg md:rounded-t-none rounded-b-lg py-3   border-t-[1px]  shadow-top overflow-hidden border-borderGray  flex-col ${view === "web" ? "hidden md:flex" : "flex md:hidden"}`}>
      <div className="flex justify-center items-center gap-5 h-16 md:h-20 bg-white">
        {!hideSave && <ButtonOutline title={buttonTitle1} onClick={onClick1} isLoading={isLoadingSave} />}
        {!hideNext && <Button title={buttonTitle2} onClick={onClick2} isLoading={isLoadingNext} />}
      </div>
    </div>
  );
};

export default DoubleButtonFooter;
