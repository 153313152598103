import Divider from "components/common/Divider";
import HcrDetailsBox from "components/common/HcrDetailsBox";
import Input from "components/Semantic/Input";
import Textarea from "components/Semantic/Textarea";
import { policyHolderSchema } from "config/schema";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { currentClaimFormDataThunk, pincodeDetailsThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";

const PolicyHolder = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoadingNext = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);
  const isLoadingSave = useAppSelector((state) => state.rootReducer.hcrSlice.isLoadingSave);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [hcrId.id]);

  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    validationSchema: policyHolderSchema,
    initialValues: {
      policyNumber: hcrDetails?.policyNumber,
      policyHolderName: hcrDetails?.policyHolderName,
      policyHolderPhone: hcrDetails?.policyHolderPhone,
      policyHolderEmail: hcrDetails?.policyHolderEmail,
      policyHolderAddress: hcrDetails?.policyHolderAddress,
      policyHolderPincode: hcrDetails?.policyHolderPincode,
      policyHolderState: hcrDetails?.policyHolderState,
      policyHolderCity: hcrDetails?.policyHolderCity
    },
    onSubmit: (values) => {
      const payload = {
        _id: hcrId.id,
        step: 4,
        ...values
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) navigate("/hcr/patient/" + data?.data?._id);
        });
    }
  });

  const saveProgressHandler = () => {
    const payload = {
      _id: hcrId.id,
      ...values
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  useEffect(() => {
    if (values.policyHolderPincode && values.policyHolderPincode.toString().length === 6) {
      dispatch(pincodeDetailsThunk({ pinCode: values.policyHolderPincode }))
        .unwrap()
        .then((data) => {
          if (data?.status === 200) {
            setFieldValue("policyHolderState", data?.data?.state);
            setFieldValue("policyHolderCity", data?.data?.city);
          }
        });
    }
  }, [values.policyHolderPincode]);
  return (
    <HcrDetailsBox progress={30} nextAction={handleSubmit} saveProgress={saveProgressHandler} title="Details Of Policyholder" isLoadingNext={isLoadingNext} isLoadingSave={isLoadingSave}>
      <p className="font-bold text-[0.75rem] md:text-[0.9rem] px-5 pt-5 ">Please Check the following Information is correct</p>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-8 px-4 md:px-5 pb-10 overflow-scroll ">
        <Input label="Policy Number *" name="policyNumber" placeholder="Policy Number" value={values.policyNumber} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Policyholder Name *" name="policyHolderName" placeholder="Policyholder Name" value={values.policyHolderName} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Phone Number *" name="policyHolderPhone" placeholder="Phone Number" type="number " limit={10} value={values.policyHolderPhone} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Email *" name="policyHolderEmail" placeholder="Email Address" type="email" value={values.policyHolderEmail} onChange={handleChange} errors={errors} touched={touched} />
        <Divider />
        <span className="row-span-2 h-full">
          <Textarea name="policyHolderAddress" parentClassName="h-full pb-7" label="Address *" placeholder="Address Line" value={values.policyHolderAddress} onChange={handleChange} errors={errors} touched={touched} />
        </span>
        <Input label="Pincode *" name="policyHolderPincode" type="number" limit={6} placeholder="Pin Code" value={values.policyHolderPincode} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="State *" name="policyHolderState" placeholder="State" value={values.policyHolderState} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="City *" name="policyHolderCity" placeholder="City" value={values.policyHolderCity} onChange={handleChange} errors={errors} touched={touched} />
      </div>
    </HcrDetailsBox>
  );
};

export default PolicyHolder;
