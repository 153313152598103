import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIList } from "config/apiList";
import { baseUrl } from "config/defaultUrl";
import axios from "utils/axios";

export const uploadDocuments = createAsyncThunk("hcr/uploadDocuments", async () => {
  const response = await axios.get(baseUrl + APIList.getAllCompanies);
  return response.data;
});

export const getClaimFilingThunk = createAsyncThunk("hcr/getClaimFilingThunk", async () => {
  const response = await axios.get(baseUrl + APIList.getClaimFilingData);
  return response.data;
});

export const uploadPolicyDocThunk = createAsyncThunk("hcr/uploadPolicyDocThunk", async (payload: { query: any; body: any }) => {
  const response = await axios.post(baseUrl + APIList.uploadDocument + `${payload.query?.userId}/${payload.query?.name}/${payload.query?.phone}/${payload.query?.policyType}`, payload.body, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const currentClaimFormDataThunk = createAsyncThunk("hcr/currentClaimFormDataThunk", async (userId: string) => {
  const response = await axios.get(baseUrl + APIList.currentClaimForm + userId);
  return response.data;
});

export const updateFormV2Thunk = createAsyncThunk("hcr/updateFormV2Thunk", async (payload: any) => {
  const response = await axios.post(baseUrl + APIList.updateForm, payload);
  return response.data;
});

export const updateFormV2ThunkForSave = createAsyncThunk("hcr/updateFormV2ThunkForSave", async (payload: any) => {
  const response = await axios.post(baseUrl + APIList.updateForm, payload);
  return response.data;
});

export const getUserDetails = createAsyncThunk("hcr/getUserDetails", async () => {
  const response = await axios.get(baseUrl + APIList.userProfile);
  return response.data;
});

export const claimFileDocThunk = createAsyncThunk("hcr/claimFileDocThunk", async () => {
  const response = await axios.post(baseUrl + APIList.getClaimFileDocumentList);
  return response.data;
});

export const ifscBankDetailsThunk = createAsyncThunk("hcr/ifscBankDetailsThunk", async (payload: { bankIfsc: string }) => {
  const response = await axios.post(baseUrl + APIList.getBankFromIFSC, payload);
  return response.data;
});

export const getDisclaimerThunk = createAsyncThunk("hcr/getDisclaimerThunk", async () => {
  const response = await axios.get(baseUrl + APIList.getDisclaimer);
  return response.data;
});

export const transactionThunk = createAsyncThunk("hcr/transactionThunk", async (payload: any) => {
  const response = await axios.post(baseUrl + APIList.getTransaction, payload);
  return response.data;
});

export const getOnEmailThunk = createAsyncThunk("hcr/getOnEmailThunk", async (payload: { _id: string }) => {
  const response = await axios.post(baseUrl + APIList.getOnEmail, payload);
  return response.data;
});

export const pincodeDetailsThunk = createAsyncThunk("hcr/pincodeDetailsThunk", async (payload: { pinCode: string }) => {
  const response = await axios.post(baseUrl + APIList.getStateFromPincode, payload);
  return response.data;
});

export const lumpSumListThunk = createAsyncThunk("hcr/lumpSumListThunk", async () => {
  const response = await axios.post(baseUrl + APIList.getlumpSumList);
  return response.data;
});
