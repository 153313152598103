import React from "react";
import BorderCard from "components/common/BorderCard";
import MobIcon from "assets/icons/mob.svg";
import SingleButtonFooter from "components/common/SingleButtonFooter";
import BottomContent from "components/common/BottomContent";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "config/schema";
import FormInput from "components/Semantic/FormInput";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { createUserThunk } from "store/thunk/main.thunk";

interface initialValuesTypes {
  polHolderName: string;
  polHolderEmail: string;
  polHolderPhone: string;
}

const initialValues: initialValuesTypes = {
  polHolderName: "",
  polHolderEmail: "",
  polHolderPhone: ""
};

const InitScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.rootReducer.mainSlice.loading);

  const { values, handleSubmit, handleBlur, handleChange, touched, errors }: any = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values: any) => {
      dispatch(createUserThunk({ ...values, polHolderPhone: String(values.polHolderPhone) }))
        .unwrap()
        .then((data: any) => {
          if (data.success) {
            navigate("/otp");
          }
        });
    }
  });
  const formProps = { handleChange, handleBlur, values, touched, errors };

  return (
    <>
      <p className="pl-5 pb-2 md:hidden block font-extrabold text-lg">Policyholder Details</p>
      <BorderCard className="w-[90%] md:w-[70%] lg:w-[50%]">
        <div className="pt-0 md:pt-10 px-4 md:px-7 pb-5 md:pb-12 ">
          <p className="font-extrabold hidden md:block text-2xl">Policyholder Details</p>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-4 md:gap-8">
            <FormInput label="Name *" placeholder="Policyholder Name" formProps={formProps} name="polHolderName" />
            <FormInput label="Mobile Number *" limit={10} type="tel" placeholder="Policyholder Mobile Number" icon={<img src={MobIcon} />} formProps={formProps} name="polHolderPhone" />
            <FormInput label="Email *" type="email" placeholder="Policyholder Email" formProps={formProps} name="polHolderEmail" />
          </div>
        </div>
        <SingleButtonFooter onClick={handleSubmit} buttonTitle="NEXT" view="web" className={isLoading ? "opacity-50" : ""} isLoading={isLoading} />
      </BorderCard>
      <BottomContent>
        <SingleButtonFooter onClick={handleSubmit} buttonTitle="NEXT" view="mobile" className={isLoading ? "opacity-50" : ""} isLoading={isLoading} />
      </BottomContent>
    </>
  );
};

export default InitScreen;
