export const APIList = {
  getAllCompanies: "insurance_company/getByPolicyId?policyTypeId=",
  createUser: "user/createUser",
  verifyOtp: "user/verifyOtp",
  docUpload: "filtration/claimfiling/documentupload",
  getClaimFilingData: "filtration/getUserClaimFiling",
  uploadDocument: "filtration/claimfiling/documentupload/",
  currentClaimForm: "filtration/claimFormDataV2/",
  updateForm: "filtration/updateFormV2/",
  userProfile: "user/profile",
  getClaimFileDocumentList: "filtration/claimFileDocumentList/",
  getBankFromIFSC: "filtration/ifscOfBank/",
  getDisclaimer: "filtration/disclaimer",
  getTransaction: "transaction/hcr",
  getOnEmail: "filtration/claimFileMailing",
  getStateFromPincode: "filtration/pinCodeDetails",
  getlumpSumList: "filtration/claimFileLumpSumList"
};
