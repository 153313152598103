import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

axios.interceptors.request.use(
  (config: any) => {
    const tokenString = sessionStorage.getItem("userToken");
    let token: string | null = null;

    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      if (userToken) {
        token = userToken;
      } else {
        // eslint-disable-next-line
        console.error("Invalid userInfo format or missing token property");
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    // eslint-disable-next-line
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    let errorMessage;
    if (error.response && error.response.data) {
      errorMessage = (error?.response?.data as any)?.message;
    } else {
      errorMessage = error.message;
    }
    const maxLength = 100;
    if (errorMessage.length > maxLength) {
      errorMessage = "Something went wrong";
    }
    if (errorMessage === "Token Not Found") {
      if (process.env.REACT_APP_ENV === "prod") window.location.href = "https://hcr.insurancesamadhan.com";
      else window.location.href = `https://hcr.${process.env.REACT_APP_ENV}.insurancesamadhan.com/`;
    }
    toast.error(errorMessage);
    return Promise.reject(error);
  }
);

export default axios;
