import React, { useEffect, useState } from "react";
import BorderCard from "components/common/BorderCard";
import SingleButtonFooter from "components/common/SingleButtonFooter";
import ClaimCard from "./components/claimCard";
import emptyClaim from "assets/images/empty-claim.png";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { getClaimFilingThunk } from "store/thunk/hcr.thunk";
import { useNavigate } from "react-router-dom";
import DoubleButtonFooter from "components/common/DoubleButtonFooter";

const AllClaimForm = () => {
  const [tabpane, setTabpane] = useState<"Pending" | "Completed">("Pending");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const claimFormData = useAppSelector((state) => state.rootReducer.hcrSlice);

  useEffect(() => {
    dispatch(getClaimFilingThunk());
  }, []);

  const EmptySection = () => {
    return (
      <div className="flex justify-center flex-col w-full items-center">
        <img src={emptyClaim} alt="empty claim" />
        <p className="font-bold">You haven't submitted any claims yet!</p>
      </div>
    );
  };

  return (
    <>
      <BorderCard className="w-[90%] md:w-[70%] lg:w-[50%] mb-20">
        <div className="pt-0 md:pt-10 pb-5 md:pb-12 px-4 md:px-7">
          <p className="font-extrabold hidden md:block text-2xl  ">Health Claim Reimbursement</p>
          <div className="bg-hcr-header py-2 px-5 rounded-lg my-5">
            <p className="font-bold text-sm">We are here to assist you with your claim reimbursement process.</p>
          </div>
          <div className="flex justify-center my-7">
            <div className="relative border-[#9797971F] border-[1px] shadow flex rounded-lg cursor-pointer  overflow-hidden">
              <div
                className={`absolute top-0 left-0 h-full w-1/2 bg-[#FAAA5233] transition-transform duration-300 ease-in-out`}
                style={{
                  transform: tabpane === "Pending" ? "translateX(0)" : "translateX(100%)"
                }}
              ></div>

              <p className={`relative z-10 w-1/2 py-3 px-10 md:px-16 font-bold text-center ${tabpane === "Pending" ? "text-polyfixTheme" : ""}`} onClick={() => setTabpane("Pending")}>
                Pending
              </p>
              <p className={`relative z-10 w-1/2 py-3 px-10 md:px-16 font-bold text-center ${tabpane === "Completed" ? "text-polyfixTheme" : ""}`} onClick={() => setTabpane("Completed")}>
                Completed
              </p>
            </div>
          </div>
          {tabpane === "Pending" ? (
            <div className="flex gap-5 flex-wrap mt-5">
              {claimFormData.claimFormLoading ? (
                <ClaimCard pending claimData={""} isLoading={true} />
              ) : claimFormData?.allClaimForms?.pending.length > 0 ? (
                claimFormData?.allClaimForms?.pending.map((cardData: any, index: number) => {
                  return <ClaimCard pending key={index} claimData={cardData} isLoading={false} />;
                })
              ) : (
                <EmptySection />
              )}
            </div>
          ) : (
            <div className="flex gap-5 flex-wrap mt-5">
              {claimFormData.claimFormLoading ? (
                <ClaimCard pending claimData={""} isLoading={true} />
              ) : claimFormData?.allClaimForms?.complete.length > 0 ? (
                claimFormData?.allClaimForms?.complete.map((cardData: any, index: number) => {
                  return <ClaimCard pending={false} key={index} claimData={cardData} isLoading={false} />;
                })
              ) : (
                <EmptySection />
              )}
            </div>
          )}
        </div>
        <SingleButtonFooter
          onClick={() => {
            navigate("/upload-policy");
          }}
          buttonTitle="Add New Claim"
          view="web"
        />
      </BorderCard>
      <DoubleButtonFooter
        hideSave={true}
        hideNext={false}
        buttonTitle1=""
        buttonTitle2="Add New Claim"
        onClick1={() => {
          navigate("/upload-policy");
        }}
        onClick2={() => {
          navigate("/upload-policy");
        }}
        view="mobile"
      />
    </>
  );
};

export default AllClaimForm;
