import Modal from "components/common/Modal";
import React, { FC } from "react";

interface SentEmailModalProps {
  showModal: boolean;
  onClose: any;
  hcrDetails: any;
}

const SentEmailModal: FC<SentEmailModalProps> = ({ showModal, onClose, hcrDetails }) => {
  const mails = `${hcrDetails.policyId && hcrDetails.policyId.owner && hcrDetails.policyId.owner.email ? hcrDetails.policyId.owner.email + "," : ""}${hcrDetails.policyId && hcrDetails.policyId.creator && hcrDetails.policyId.creator.email ? hcrDetails.policyId.creator.email + "," : ""}${hcrDetails.policyHolderEmail ? hcrDetails.policyHolderEmail : ""}`;
  return (
    <Modal isOpen={showModal} onClose={onClose} className="w-[30rem] bg-white" style={{ boxShadow: "0px 8px 32px 0px #493E8320" }}>
      <p className="p-5 font-medium" style={{ boxShadow: "0px 8px 32px 0px #493E8320" }}>
        Claim form has been successfully shared on <span className="font-bold">{mails}</span>
      </p>
      <div className="py-5 flex justify-center">
        <button onClick={onClose} className={`bg-polyfix-gradient h-12 rounded-lg min-w-44 md:min-w-52 font-bold flex items-center justify-center gap-3 `}>
          OK
        </button>
      </div>
    </Modal>
  );
};

export default SentEmailModal;
