import Modal from "components/common/Modal";
import React, { FC } from "react";
import arrow from "assets/icons/green-arrow.svg";
import { docList } from "config/constants";
import SingleButtonFooter from "components/common/SingleButtonFooter";
import { useNavigate } from "react-router-dom";

interface DocListModalProps {
  showModal: boolean;
  onClose: any;
  hcrId: string;
}

const DocListModal: FC<DocListModalProps> = ({ showModal, onClose, hcrId }) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={showModal} onClose={onClose} className="w-[30rem]">
      <p className="font-bold text-[16px] mb-4 px-5">For a seamless experience, please make sure that you’ve the following documents handy:</p>
      {docList.map((docName: any, index: number) => {
        return (
          <div key={index} className={`flex gap-2 mx-5 mb-3 items-center ${docList.length !== index + 1 && " border-b-[1px]"} border-gray-200 py-3`}>
            <img src={arrow} alt="arrow" className="bg-transparent z-10" />
            <p className="font-bold">{docName}</p>
          </div>
        );
      })}
      <SingleButtonFooter
        onClick={() => {
          navigate("/hcr/hospitalisation-details/" + hcrId);
        }}
        buttonTitle="NEXT"
        view={"both"}
      />
    </Modal>
  );
};

export default DocListModal;
