import React, { useEffect } from "react";
import HcrDetailsBox from "components/common/HcrDetailsBox";
import Checkbox from "components/Semantic/Checkbox";
import Textarea from "components/Semantic/Textarea";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { claimFileDocThunk, currentClaimFormDataThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import { toast } from "react-toastify";

const labelClassName = "text-sm font-medium";
const countArr = [1, 3, 5, 7, 9, 11, 13, 15, 17];

const Loader = () => {
  return (
    <div className="flex justify-center">
      <div className="w-8 h-8  border-[2px] border-t-[#dea15c]  rounded-full  animate-spin"></div>
    </div>
  );
};

const DocChecklist = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);

  const claimFileDocList = useAppSelector((state) => state.rootReducer.hcrSlice.claimFileDoc);
  const isLoading = useAppSelector((state) => state.rootReducer.hcrSlice.claimFileDocLoading);
  const isLoadingSave = useAppSelector((state) => state.rootReducer.hcrSlice.isLoadingSave);

  useEffect(() => {
    dispatch(claimFileDocThunk());
  }, []);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [dispatch, hcrId.id]);

  const { values, setFieldValue, handleSubmit, handleChange, setFieldError, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      claimDocumentChecklist: hcrDetails?.claimDocumentChecklist || [],
      otherCheckListDocumentSummary: hcrDetails?.otherCheckListDocumentSummary || ""
    },
    onSubmit: (values) => {
      if (!validationChecker()) {
        return;
      }

      const payload = {
        _id: hcrId.id,
        step: 3,
        ...values
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) navigate("/hcr/policyholder/" + hcrId?.id);
        });
    }
  });

  const handleCheckboxChange = (checkboxTitle: string) => {
    const currentValues = values.claimDocumentChecklist;
    const isChecked = currentValues.some((obj: any) => obj.title === checkboxTitle);

    if (isChecked) {
      setFieldValue(
        "claimDocumentChecklist",
        currentValues.filter((obj: any) => obj.title !== checkboxTitle)
      );
    } else {
      setFieldValue("claimDocumentChecklist", [...currentValues, { title: checkboxTitle }]);
    }
  };

  const validationChecker = () => {
    const requiredDocs = claimFileDocList.filter((doc) => doc.required).map((doc) => doc.title);
    const submittedDocs = values.claimDocumentChecklist.map((doc: any) => doc.title);
    const missingDocs = requiredDocs.filter((doc) => !submittedDocs.includes(doc));

    if (values.claimDocumentChecklist.find((obj: any) => obj.title === "Others") && !values.otherCheckListDocumentSummary.trim()) {
      setFieldError("otherCheckListDocumentSummary", "Please enter other document details");
      return false;
    }

    if (missingDocs.length === 0) return true;
    else {
      toast.error("Tick all the required docs");
      return false;
    }
  };

  const saveProgressHandler = () => {
    const payload = {
      _id: hcrId.id,
      ...values
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  return (
    <HcrDetailsBox progress={20} nextAction={handleSubmit} title="Claim Documents Checklist" saveProgress={saveProgressHandler} isLoadingSave={isLoadingSave}>
      <p className="font-bold text-[0.75rem] md:text-[0.9rem] px-5 pt-5 ">Kindly tick all the documents that you will atach with the claim form and submit to the insurance company.</p>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-6  px-5 pb-4 max-h-[26rem] overflow-y-scroll ">
        {isLoading ? (
          <Loader />
        ) : (
          claimFileDocList &&
          claimFileDocList.map((checkbox: any, index: number) => {
            return (
              <>
                <Checkbox checked={values.claimDocumentChecklist.find((obj: any) => obj.title === checkbox.title)} onChange={() => handleCheckboxChange(checkbox.title)} labelClassName={labelClassName} label={`${checkbox?.title} ${checkbox?.required ? "*" : ""}`} key={index} />
                {countArr.includes(index) && <div className="border-t-[1px] border-dashed hidden md:block col-span-2 border-[#00000033] w-full mx-auto my-4"></div>}
                <div className="border-t-[1px] border-dashed block md:hidden  border-[#00000033] w-full mx-auto my-4"></div>
              </>
            );
          })
        )}
        {values.claimDocumentChecklist && values.claimDocumentChecklist.find((obj: any) => obj.title === "Others") && (
          <span className="row-span-2 h-full">
            <Textarea name="otherCheckListDocumentSummary" parentClassName="h-full pb-7" label="" placeholder="Other" value={values?.otherCheckListDocumentSummary} onChange={handleChange} errors={errors} touched={touched} />
          </span>
        )}
      </div>
    </HcrDetailsBox>
  );
};

export default DocChecklist;
