import React, { FC } from "react";

interface RadioButtonProps {
  label: string;
  labelClassName?: string;
  radioClassName?: string;
  onChange?: any;
  name: string;
  value: string | undefined;
}

const RadioButton: FC<RadioButtonProps> = ({ label, labelClassName, onChange, name, value, radioClassName }) => {
  const radioTrue = (
    <div className={`w-5 h-5 rounded-full bg-polyfix-gradient flex items-center justify-center transition-all duration-300 ${radioClassName}`}>
      <div className="w-1.5 h-1.5 rounded-full bg-white transition-all duration-300"></div>
    </div>
  );

  const radioFalse = <div className={`w-5 h-5 rounded-full bg-white border-[1px] border-black flex items-center justify-center transition-all duration-300 ${radioClassName}`}></div>;

  return (
    <div className="flex items-center">
      <label className="relative inline-flex gap-3 items-center cursor-pointer" onClick={onChange}>
        {value === name ? radioTrue : radioFalse}
        <p className={`font-semibold text-sm ${labelClassName}`}>{label}</p>
      </label>
    </div>
  );
};

export default RadioButton;
