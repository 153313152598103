import HcrDetailsBox from "components/common/HcrDetailsBox";
import React, { useEffect, useState } from "react";
import emailIcon from "assets/icons/email.svg";
import downloadIcon from "assets/icons/download.svg";
import tickIcon from "assets/icons/disclaimer-tick.svg";
import circle from "assets/icons/circle.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { currentClaimFormDataThunk, getOnEmailThunk } from "store/thunk/hcr.thunk";
import { toast } from "react-toastify";
import SentEmailModal from "./components/modals/SentEmailModal";

const DownloadForm = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showEmailModal, setShowEmailModal] = useState(false);

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [hcrId]);

  const getOnEmailHandler = () => {
    setShowEmailModal(true);
    dispatch(getOnEmailThunk({ _id: hcrDetails?._id })).unwrap();
  };

  const downloadClaimFormHandler = async () => {
    if (!hcrDetails?.claimFormUrl) toast.error("Pdf Not Found");
    try {
      const response = await fetch(hcrDetails?.claimFormUrl);
      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const url: any = window.URL.createObjectURL(blob);

      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Claim Form.pdf");
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <div className="bg-[#F0F4FD] my-4 flex md:hidden gap-4 p-5 mt-7">
        <button onClick={downloadClaimFormHandler} className=" bg-white font-bold flex gap-3 items-center px-3 py-3 border-[1px] border-borderGray rounded-lg shadow-md">
          <img src={downloadIcon} alt="download-icon" />
          <p className="text-xs">Download Claim Form</p>
        </button>
        <button onClick={getOnEmailHandler} className=" bg-white flex font-bold gap-3 px-3 items-center py-3 border-[1px] border-borderGray rounded-lg shadow-md">
          <img src={emailIcon} alt="download-icon" />
          <p className="text-xs">Get It On Email</p>
        </button>
      </div>
      <HcrDetailsBox hideSave={true} hideProgress={true} hideNext={true} progress={100} title="Download Claim Form" parentClassName="-mt-0">
        <div className="bg-[#F0F4FD] my-4 hidden md:flex gap-4 p-8">
          <button onClick={downloadClaimFormHandler} className=" bg-white font-bold flex gap-3 px-5 py-4 border-[1px] border-borderGray rounded-lg shadow-md">
            <img src={downloadIcon} alt="download-icon" />
            <p>Download Claim Form</p>
          </button>
          <button onClick={getOnEmailHandler} className=" bg-white flex font-bold gap-3 px-5 py-4 border-[1px] border-borderGray rounded-lg shadow-md">
            <img src={emailIcon} alt="download-icon" />
            <p>Get It On Email</p>
          </button>
        </div>
        <div className="m-5 bg-[#F0F4FD] border-[1px] border-borderGray rounded-lg overflow-hidden">
          <header className="p-5 font-bold">Points to Remember</header>
          <div className="bg-white rounded-t-3xl shadow-all p-5 space-y-4 pb-10 text-sm font-medium">
            <div className="flex gap-3 items-start">
              <img src={tickIcon} alt="tick-icon" className="mt-[3px]" />
              <p>Please ensure all the documents are submitted in original for smooth processing of claim.</p>
            </div>
            <div className="flex gap-3 items-start">
              <img src={tickIcon} alt="tick-icon" className="mt-[3px]" />
              <div>
                <p>Claim documents needs to be send on below address:</p>
                <p>Care Health Insurance-Claims Department</p>
                <p>Unit No. 604 - 607, 6th Floor, Tower C, Unitech Cyber Park, Sector-39,</p>
                <p>Gurugram-122001 (Haryana)</p>
              </div>
            </div>
            <div className="flex gap-3 items-start">
              <img src={tickIcon} alt="tick-icon" className="mt-[3px]" />
              <p>
                Claim payments are made only through Online Bank Transfers. Please submit the Bank
                <br /> Account details along with a cancelled cheque
              </p>
            </div>
            <div className="flex gap-3 items-start">
              <img src={tickIcon} alt="tick-icon" className="mt-[3px]" />
              <p>In Case of Medico legal or Reported to Police, it is suggested that you attach the MLC/FIR copy for smooth processing of your claim</p>
            </div>

            <div className="flex gap-3 items-start">
              <img src={tickIcon} alt="tick-icon" className="mt-[3px]" />
              <div>
                <p>Please ensure the signatures on the claim form as below:</p>
                <p className="flex gap-2">
                  <img src={circle} alt="circle" />
                  Part A – Section H: Insured Person
                </p>
                <p className="flex gap-2">
                  <img src={circle} alt="circle" />
                  Part B – Section F: Hospital{" "}
                </p>
                <p className="flex gap-2">
                  <img src={circle} alt="circle" />
                  Consent Letter (at the end of Claim Form): Insured Person{" "}
                </p>{" "}
              </div>
            </div>
          </div>
          <div className={`fixed flex md:relative bottom-0 left-0 z-10 bg-white  w-full rounded-b-lg py-3   border-t-[1px]  shadow-top overflow-hidden border-borderGray  flex-col`}>
            <div className="flex justify-center items-center gap-5 h-16 md:h-20 bg-white">
              <button onClick={() => navigate("/hcr/all-claim-form")} className={`bg-polyfix-gradient h-12 rounded-lg min-w-44 md:min-w-80 font-bold flex items-center justify-center gap-3 `}>
                <p>Go To Home</p>
              </button>
            </div>
          </div>
        </div>
        <SentEmailModal showModal={showEmailModal} hcrDetails={hcrDetails} onClose={() => setShowEmailModal(!showEmailModal)} />
      </HcrDetailsBox>
    </>
  );
};

export default DownloadForm;
