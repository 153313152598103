import React, { useEffect, useState } from "react";

const ProgressBar = ({ percentage }: { percentage: number }) => {
  const [currentPercentage, setCurrentPercentage] = useState(percentage);

  useEffect(() => {
    setCurrentPercentage(Math.max(0, Math.min(100, percentage)));
  }, [percentage]);

  return (
    <div className="relative w-[22rem] md:w-96 h-4 md:h-5 border-[#FBB451] border-[1px] rounded-3xl overflow-hidden">
      <div className="h-full bg-polyfix-gradient transition-all duration-500 ease-in-out" style={{ width: `${currentPercentage}%` }}></div>
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-black text-xs font-bold">{currentPercentage}%</p>
      </div>
    </div>
  );
};

export default ProgressBar;
