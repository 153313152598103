import { createSlice } from "@reduxjs/toolkit";
import { claimFileDocThunk, currentClaimFormDataThunk, getClaimFilingThunk, getDisclaimerThunk, lumpSumListThunk, updateFormV2Thunk, updateFormV2ThunkForSave, uploadPolicyDocThunk } from "store/thunk/hcr.thunk";

interface initialStateProps {
  loading: boolean;
  error: string | null;
  allClaimForms: { pending: any[]; complete: any[] };
  claimFormLoading: boolean;
  docList: any[];
  currentClaimForm: any;
  updateFormLoading: boolean;
  uploadPolicyFormValues: any;
  claimFileDoc: any[];
  claimFileDocLoading: boolean;
  uploadDocLoading: boolean;
  disclaimerData: any;
  isLoadingSave: boolean;
  lumpSumList: any;
}

const initialState: initialStateProps = {
  loading: false,
  error: null,
  allClaimForms: { pending: [], complete: [] },
  claimFormLoading: false,
  docList: [],
  currentClaimForm: "",
  updateFormLoading: false,
  uploadPolicyFormValues: { insuranceCompanyId: { name: "Select Insurance Company", _id: "" }, typeOfPolicy: { label: "Select Policy Type", value: "" } },
  claimFileDoc: [],
  claimFileDocLoading: false,
  uploadDocLoading: false,
  disclaimerData: "",
  isLoadingSave: false,
  lumpSumList: []
};

const hcrSlice = createSlice({
  name: "HCR Slice",
  initialState,
  reducers: {
    saveDocList: (state, action) => {
      state.docList = action.payload;
    },

    uploadFormValuesHandler: (state, action) => {
      state.uploadPolicyFormValues = { ...state.uploadPolicyFormValues, [action.payload.name]: action.payload.value };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimFilingThunk.pending, (state) => {
        state.claimFormLoading = true;
      })
      .addCase(getClaimFilingThunk.fulfilled, (state, action) => {
        state.claimFormLoading = false;
        state.allClaimForms = action.payload.data;
        state.error = null;
      })
      .addCase(getClaimFilingThunk.rejected, (state, action) => {
        state.claimFormLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(currentClaimFormDataThunk.pending, (state) => {
        state.claimFormLoading = true;
      })
      .addCase(currentClaimFormDataThunk.fulfilled, (state, action) => {
        state.claimFormLoading = false;
        state.currentClaimForm = action.payload.data;
        state.error = null;
      })
      .addCase(currentClaimFormDataThunk.rejected, (state, action) => {
        state.claimFormLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(updateFormV2Thunk.pending, (state) => {
        state.updateFormLoading = true;
      })
      .addCase(updateFormV2Thunk.fulfilled, (state) => {
        state.updateFormLoading = false;
        state.error = null;
      })
      .addCase(updateFormV2Thunk.rejected, (state, action) => {
        state.updateFormLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(claimFileDocThunk.pending, (state) => {
        state.claimFileDocLoading = true;
      })
      .addCase(claimFileDocThunk.fulfilled, (state, action) => {
        state.claimFileDocLoading = false;
        state.claimFileDoc = action.payload.data;
        state.error = null;
      })
      .addCase(claimFileDocThunk.rejected, (state, action) => {
        state.claimFileDocLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(uploadPolicyDocThunk.pending, (state) => {
        state.uploadDocLoading = true;
      })
      .addCase(uploadPolicyDocThunk.fulfilled, (state) => {
        state.uploadDocLoading = false;
        state.error = null;
      })
      .addCase(uploadPolicyDocThunk.rejected, (state, action) => {
        state.uploadDocLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getDisclaimerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDisclaimerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.disclaimerData = action.payload?.data;
        state.error = null;
      })
      .addCase(getDisclaimerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(updateFormV2ThunkForSave.pending, (state) => {
        state.isLoadingSave = true;
      })
      .addCase(updateFormV2ThunkForSave.fulfilled, (state) => {
        state.isLoadingSave = false;
        state.error = null;
      })
      .addCase(updateFormV2ThunkForSave.rejected, (state, action) => {
        state.isLoadingSave = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(lumpSumListThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(lumpSumListThunk.fulfilled, (state, action) => {
        state.lumpSumList = action.payload?.data;
        state.error = null;
      })
      .addCase(lumpSumListThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { saveDocList, uploadFormValuesHandler } = hcrSlice.actions;

export default hcrSlice.reducer;
