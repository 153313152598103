import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIList } from "config/apiList";
import { baseUrl } from "config/defaultUrl";
import axios from "utils/axios";

interface createUserArgs {
  polHolderEmail: string;
  polHolderName: string;
  polHolderPhone: string;
}

interface VerifyOtpArgs extends createUserArgs {
  otp: string | number;
}

export const getAllCompaniesThunk = createAsyncThunk("getAllCompaniesThunk", async () => {
  const response = await axios.get(baseUrl + APIList.getAllCompanies);
  return response.data;
});

export const createUserThunk = createAsyncThunk("user/createUserThunk", async (payload: createUserArgs) => {
  const response = await axios.post(baseUrl + APIList.createUser, payload);
  return response.data;
});

export const verifyOtpThunk = createAsyncThunk("user/verifyOtpThunk", async (payload: VerifyOtpArgs) => {
  const response = await axios.post(baseUrl + APIList.verifyOtp, payload);
  return response.data;
});
