import React, { FC, ReactNode } from "react";

interface ButtonProps {
  title: string;
  onClick: any;
  isLoading?: boolean;
  icon?: ReactNode;
  className?: string;
}

const ButtonOutline: FC<ButtonProps> = ({ title, onClick, isLoading, icon, className }) => {
  return (
    <button className={`border-[1.5px] flex items-center justify-center gap-2 border-black h-12 hover:bg-black hover:text-white rounded-lg min-w-44 md:min-w-52 font-bold ${isLoading && "opacity-50 pointer-events-none"} ${className}`} onClick={onClick}>
      {isLoading && <div className="w-4 h-4 border-[2px] border-t-[#dea15c]  rounded-full animate-spin"></div>}
      {title}
      {icon ? icon : ""}
    </button>
  );
};

export default ButtonOutline;
