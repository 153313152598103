import React from "react";
import insaLogo from "assets/images/insaLogo.svg";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const navigationHandler = () => {
    if (sessionStorage.getItem("userToken")) navigate("/hcr/all-claim-form");
    else navigate("/");
  };
  return (
    <nav className="py-2 z-50 fixed top-0 w-screen flex justify-center shadow-lg md:shadow-xl h-[3.7rem] px-2 md:h-[4.5rem] bg-white">
      <img src={insaLogo} alt="insa-logo" className="w-40 md:w-52 cursor-pointer" onClick={navigationHandler} />
    </nav>
  );
};

export default Navbar;
