import Modal from "components/common/Modal";
import React, { FC } from "react";

interface BillAlertModalProps {
  showModal: boolean;
  onClose: any;
}

const BillAlert: FC<BillAlertModalProps> = ({ showModal, onClose }) => {
  return (
    <Modal isOpen={showModal} onClose={onClose} className="w-[30rem] bg-white" style={{ boxShadow: "0px 8px 32px 0px #493E8320" }}>
      <p className="p-5 font-medium" style={{ boxShadow: "0px 8px 32px 0px #493E8320" }}>
        Please ensure that these bills have not already been included in the Hospital Main Bill.
      </p>
      <div className="py-5 flex justify-center">
        <button onClick={onClose} className={`bg-polyfix-gradient h-12 rounded-lg min-w-44 md:min-w-52 font-bold flex items-center justify-center gap-3 `}>
          OK
        </button>
      </div>
    </Modal>
  );
};

export default BillAlert;
