import Button from "components/Semantic/Button";
import React, { FC } from "react";

interface SingleButtonFooterProps {
  buttonTitle: string;
  view: "web" | "mobile" | "both";
  onClick: any;
  className?: string;
  isLoading?: boolean;
}

const SingleButtonFooter: FC<SingleButtonFooterProps> = ({ buttonTitle, view, onClick, className, isLoading }) => {
  const classMap = {
    both: "flex",
    web: "hidden md:flex",
    mobile: "flex md:hidden"
  };
  return (
    <div className={`relative w-full rounded-b-lg  border-t-[1px]  shadow-top rounded-t-2xl md:rounded-none overflow-hidden border-borderGray  flex-col ${classMap[view] || "flex"} `}>
      <div className="flex justify-center items-center h-20 bg-white">
        <Button title={buttonTitle} onClick={onClick} className={className} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default SingleButtonFooter;
