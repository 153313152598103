import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "store/thunk/hcr.thunk";
import { createUserThunk, getAllCompaniesThunk, verifyOtpThunk } from "store/thunk/main.thunk";

interface initialStateProps {
  loading: boolean;
  error: string | null;
  companyList: any[];
  currentUserDetails: any;
}

const initialState: initialStateProps = {
  loading: false,
  error: null,
  companyList: [],
  currentUserDetails: ""
};

const mainSlice = createSlice({
  name: "Main Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompaniesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCompaniesThunk.fulfilled, (state, action) => {
        state.loading = false;
        const claimFormSupportedCompanies = action.payload?.data.filter((option: any) => option?.claimFileSupported && option?.isActive);
        state.companyList = claimFormSupportedCompanies;
        state.error = null;
      })
      .addCase(getAllCompaniesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(createUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUserDetails = action.payload?.data?.userObj;
        state.error = null;
      })
      .addCase(createUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(verifyOtpThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtpThunk.fulfilled, (state, action) => {
        state.loading = false;
        sessionStorage.setItem("userToken", JSON.stringify(action.payload.data?.token));
        state.error = null;
      })
      .addCase(verifyOtpThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUserDetails = action.payload?.data;
        state.error = null;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default mainSlice.reducer;
