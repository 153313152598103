import HcrDetailsBox from "components/common/HcrDetailsBox";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { currentClaimFormDataThunk, transactionThunk } from "store/thunk/hcr.thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import insaLogo from "assets/images/insaLogoWithoutText.png";
import Checkbox from "components/Semantic/Checkbox";
import { toast } from "react-toastify";
import { usePdf } from "@mikecousins/react-pdf";

const ClaimForm = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const canvasRef: any = useRef(null);
  const dispatch = useAppDispatch();

  const [isAgreed, setIsAgreed] = useState(false);

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoading = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);

  const { pdfDocument } = usePdf({
    file: hcrDetails?.claimFormUrl || "https://mag.wcoomd.org/uploads/2018/05/blank.pdf",
    page: 1,
    canvasRef
  });

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [hcrId]);

  const submitHandler = () => {
    if (hcrDetails.isComplete && !isAgreed) {
      toast.error("Please accept disclaimer");
      return;
    }

    if (hcrDetails.isComplete) {
      navigate("/hcr/download-form/" + hcrDetails?._id);
      return;
    }

    dispatch(transactionThunk({ policyId: hcrDetails?.policyId }))
      .unwrap()
      .then(async (data) => await displayRazorpay(data?.data));
  };
  async function displayRazorpay(data: any) {
    const options: any = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: data?.amount || 799,
      name: "Insurance Samadhan",
      description: "Pay for Policies " + sessionStorage.getItem("complaintTypeId"),
      image: insaLogo,
      prefill: {
        email: data.email,
        contact: data.phone
      },
      notes: [data.txnId],
      theme: {
        color: "#000000"
      },
      handler: async function () {
        try {
          dispatch(currentClaimFormDataThunk(hcrId.id));
        } catch (error) {
          // eslint-disable-next-line
          console.log("Something went wrong");
        }
      }
    };
    const paymentObject: any = new (window as any).Razorpay(options);
    paymentObject.open();
  }

  console.log(hcrDetails?.claimFormUrl);

  return (
    <>
      <HcrDetailsBox hideNext={true} hideProgress={true} hideSave={true} progress={100} title="Here’s Your Claim Form" isLoadingNext={isLoading}>
        <div className="m-5 bg-[#F0F4FD] border-[1px] border-borderGray rounded-lg overflow-hidden">
          <header className="p-5 font-bold">CLAIM FORM</header>
          <div className={`bg-white rounded-t-3xl shadow-all p-5 space-y-4   ${!hcrDetails?.isComplete && "blur-2xl pointer-events-none"} `}>
            {!pdfDocument ? (
              <div className="flex gap-3 items-center flex-col h-full">
                <div className="w-4 h-4 border-[2px] border-t-[#dea15c]  rounded-full animate-spin"></div>
                Loading PDF
              </div>
            ) : (
              <canvas ref={canvasRef} className="h-full w-full" />
            )}
          </div>
          <div className={`p-5 bg-white ${!hcrDetails?.isComplete && "blur-2xl pointer-events-none"}`}>
            <Checkbox checkboxClassName={"w-8 h-6 min-w-6"} checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} labelClassName="text-xs md:text-sm ml-2" label="I hereby confirm that the Part A of this claim form will be duly signed by the policy holder and Part B will be signed and stamped by the hospital." />
          </div>
        </div>
        <div className={`fixed hidden md:relative bottom-0 z-10 bg-white  w-full rounded-b-lg py-3   border-t-[1px]  shadow-top overflow-hidden border-borderGray  flex-col md:flex`}>
          <div className="flex justify-center items-center gap-5 h-16 md:h-20 bg-white">
            <button onClick={submitHandler} className={`bg-polyfix-gradient h-12 rounded-lg ${hcrDetails?.isComplete ? "md:min-w-48" : "md:min-w-80"} font-bold flex items-center justify-center gap-3 `}>
              <p>{hcrDetails?.isComplete ? "Next" : "Pay Rs. 799 to generate the form"}</p>
            </button>
          </div>
        </div>
      </HcrDetailsBox>
      <div className={`fixed flex md:relative bottom-0 z-10 bg-white  w-full rounded-b-lg py-3   border-t-[1px]  shadow-top overflow-hidden border-borderGray  flex-col md:hidden`}>
        <div className="flex justify-center items-center gap-5 h-16 md:h-20 bg-white">
          <button onClick={submitHandler} className={`bg-polyfix-gradient h-12 rounded-lg ${hcrDetails?.isComplete ? "min-w-48" : "min-w-80"} font-bold flex items-center justify-center gap-3 `}>
            <p>{hcrDetails?.isComplete ? "Next" : "Pay Rs. 799 to generate the form"}</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default ClaimForm;
