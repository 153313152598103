export const customStyles = {
  indicatorSeparator: () => ({
    display: "none"
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: "45px",
    height: "auto",
    borderColor: state.isFocused ? "#2684FF" : provided.borderColor,
    boxShadow: state.isFocused ? "0 0 0 1px #2684FF" : provided.boxShadow
  })
};

export const policyTypesConstant: { value: string; label: string }[] = [
  { value: "", label: "Select Option" },
  { value: "Individual", label: "Individual" },
  { value: "Family", label: "Family" },
  { value: "Group", label: "Group" },
  { value: "Bancassurance", label: "Bancassurance" },
  { value: "Others", label: "Others" }
];

export const typeOfAdmission: { id: number; value: string; label: string }[] = [
  {
    id: 0,
    value: "Emergency",
    label: "Emergency"
  },
  {
    id: 1,
    value: "Planned",
    label: "Planned"
  },
  {
    id: 2,
    value: "Day Care",
    label: "Day Care"
  },
  {
    id: 3,
    value: "Maternity",
    label: "Maternity"
  }
];

export const incomeValue: { value: string; label: string }[] = [
  { label: "Up to 50,000", value: "Up to 50,000" },
  { label: "50,001-1,00,000", value: "50,000-1,00,000" },
  { label: "1,00,001-2,00,000", value: "1,00,001-2,00,000" },
  { label: "2,00,001-3,00,000", value: "2,00,001-3,00,000" },
  { label: "3,00,001-4,00,000", value: "3,00,001-4,00,000" },
  { label: "4,00,001-5,00,000", value: "4,00,001-5,00,000" },
  { label: "Above 5 lacs", value: "Above 5 lacs" }
];

export const typeOfProcessHCR: { name: string; label: any }[] = [
  {
    label: (
      <p>
        <span className="font-semibold">YES</span>, my claim got rejected
      </p>
    ),
    name: "Yes, my claim got rejected"
  },
  {
    label: (
      <p>
        <span className="font-semibold">NO</span>, it was fully settled
      </p>
    ),
    name: "No, it was fully settled"
  },
  {
    label: (
      <p>
        <span className="font-semibold">NO</span>, it was partially settled
      </p>
    ),
    name: "No, it was partially settled"
  },
  { label: "I did not apply for Cashless", name: "I did not apply for Cashless" },
  { label: "I did not know about Cashless", name: "I did not know about Cashless" },
  { label: "There was no option for Cashless", name: "There was no option for Cashless" }
];

export const wasHealthClaimRejectedDuringCashless: { name: any; isSelected: boolean }[] = [
  {
    name: (
      <p>
        <span className="font-semibold">YES</span>, my claim got rejected
      </p>
    ),
    isSelected: false
  },
  {
    name: (
      <p>
        <span className="font-semibold">NO</span>, it was fully settled
      </p>
    ),
    isSelected: false
  },
  {
    name: (
      <p>
        <span className="font-semibold">NO</span>, it was partially settled
      </p>
    ),
    isSelected: false
  },
  { name: "I did not apply for Cashless", isSelected: false },
  { name: "I did not know about Cashless", isSelected: false },
  { name: "There was no option for Cashless", isSelected: false }
];

export const typeOfReimbursementValue: { value: string; name: string }[] = [
  { name: "Hospitalisation Expenses", value: "Hospitalisation Expenses" },
  { name: "Pre-Hospitalisation Expenses", value: "Pre-Hospitalisation Expenses" },
  { name: "Post Hospitalisation Expenses", value: "Post Hospitalisation Expenses" },
  { name: "Pre & Post Hospitalisation Expenses", value: "Pre & Post Hospitalisation Expenses" },
  { name: "Day Care", value: "Day Care Treatment Expense" }
];

export const typeOfReimbursementValueArr: string[] = ["Hospitalisation Expenses", "Pre-Hospitalisation Expenses", "Post Hospitalisation Expenses", "Pre & Post Hospitalisation Expenses", "Day Care Treatment Expense"];

export const docList: string[] = ["Insurance Policy Copy", "Discharge Summary", "Hospital Final Bill", "All other bills such as Pharmacy,Diagnostics etc.", "Bank Details of Policyholder", "First Prescription Paper (if any)"];

export const roomCategory: { id: number; value: string; label: string }[] = [
  {
    id: 0,
    value: "Day Care",
    label: "Day Care"
  },
  {
    id: 1,
    value: "Single Occupancy",
    label: "Single Occupancy"
  },
  {
    id: 2,
    value: "Twin sharing",
    label: "Twin sharing"
  },
  {
    id: 3,
    value: "3 or more beds per room",
    label: "3 or more beds per room"
  }
];

export const hospitalisationDueTo: { id: number; value: string; label: string }[] = [
  {
    id: 0,
    value: "Injury",
    label: "Injury"
  },
  {
    id: 1,
    value: "Illness",
    label: "Illness"
  },
  {
    id: 2,
    value: "Maternity",
    label: "Maternity"
  }
];

export const statusOfDischarge: { id: number; value: string; label: string }[] = [
  {
    id: 0,
    value: "Discharge to home",
    label: "Discharge to home"
  },
  {
    id: 1,
    value: "Deceased",
    label: "Deceased"
  },
  {
    id: 2,
    value: "Discharge to another",
    label: "Discharge to another"
  }
];

export const screensList: { screenName: string; route: string; screenStep: number }[] = [
  {
    screenName: "Details Of Hospitalisation",
    screenStep: 2,
    route: "/hospitalisation-details"
  },
  {
    screenName: "Claim Documents Checklist",
    screenStep: 3,
    route: "/doc-checklist"
  },
  {
    screenName: "Details Of Policyholder",
    screenStep: 4,
    route: "/policyholder"
  },
  {
    screenName: "Details Of Patient",
    screenStep: 5,
    route: "/patient"
  },
  {
    screenName: "Details Of Insurance History",
    screenStep: 6,
    route: "/insurance-history"
  },
  {
    screenName: "Details Of Claim/Bills",
    screenStep: 7,
    route: "/claim-bills"
  },
  {
    screenName: "Bank Account Details",
    screenStep: 8,
    route: "/bank-details"
  },
  {
    screenName: "Detail of hospital",
    screenStep: 9,
    route: "/hospital-details"
  },
  {
    screenName: "Disclaimer",
    screenStep: 10,
    route: "/disclaimer"
  },
  {
    screenName: "Claim Form",
    screenStep: 11,
    route: "/claim-form"
  }
];

export const relationshipArr = [
  {
    value: "Self"
  },
  {
    value: "Spouse"
  },
  {
    value: "Child"
  },
  {
    value: "Father"
  },
  {
    value: "Mother"
  },
  {
    value: "Others"
  }
];

export const occupationArr = [
  { label: "Small business Owner", value: "Small business Owner" },
  { label: "Self Employed", value: "Self Employed" },
  { label: "Serviceman/Professional", value: "Serviceman/Professional" },
  { label: "Armed Forces", value: "Armed Forces" },
  { label: "Farmer", value: "Farmer" },
  { label: "Shopkeeper", value: "Shopkeeper" },
  { label: "Businessman", value: "Businessman" },
  { label: "Housewife", value: "Housewife" },
  { label: "Retired Personnel", value: "Retired Personnel" },
  { label: "Retired from Army", value: "Retired from Army" },
  { label: "Unemployed", value: "Unemployed" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "Government Job", value: "Government Job" },
  { label: "Daily Wager", value: "Daily Wager" },
  { label: "Others", value: "Others" }
];

export const genderArr = [{ value: "Male" }, { value: "Female" }, { value: "Other" }];

export const typeOfBillsArr = [{ value: "Hospital Expense" }, { value: "Pharmacy, Diagnosis" }, { value: "Consultation Fee" }, { value: "Pharmacy, Diagnosis" }, { value: "Other" }];

export const causeOfInjuryArr = [{ value: "Self Inflicted" }, { value: "Road Traffic Accident" }, { value: "Substance Abuse" }, { value: "Alcohol Consumption" }];
