import { FormikTouched, FormikValues } from "formik";
import React from "react";

export type TextareaProps = {
  label?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  className?: string;
  onChange?: any;
  keyDownValidation?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onclick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  limit?: number;
  parentClassName?: string;
  errors?: any;
  touched?: FormikTouched<FormikValues>;
  handleBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
};

const Textarea: React.FC<TextareaProps> = ({ parentClassName, label, name = "", value, onChange, limit, errors, touched, handleBlur, ...rest }) => {
  return (
    <div className={parentClassName}>
      {label && (
        <label className="font-semibold text-sm" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="relative mt-1 h-full">
        <textarea
          {...rest}
          onBlur={handleBlur}
          value={value}
          onChange={(e: any) => {
            if (limit && e.target.value.length > limit) return;
            if (onChange && typeof onChange === "function") {
              onChange(e);
            }
          }}
          name={name}
          className=" appearance-none border h-full resize-none focus:ring-2 focus:outline-none focus:ring-black rounded w-full py-3 pl-5 px-3 text-gray-700 leading-tight focus:shadow-outline"
        />
      </div>
      {touched?.[name] && errors?.[name] && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.[name]}</p>}
    </div>
  );
};

export default Textarea;
