import React, { FC, useState, useRef, useEffect } from "react";
import billLabel from "assets/icons/bill-icon.svg";
import arrowCircle from "assets/icons/arrow-circle.svg";
import arrow from "assets/icons/arrow.svg";
import Input from "components/Semantic/Input";
import calenderIcon from "assets/icons/calender.svg";
import deleteIcon from "assets/icons/delete-icon.svg";
import ButtonOutline from "components/Semantic/ButtonOutline";
import { useAppSelector } from "utils/hooks";
import ReactSelect from "react-select";
import { customStyles, typeOfBillsArr } from "config/constants";
import Textarea from "components/Semantic/Textarea";
import BillAlert from "./modals/BillAlert";

interface BillsCardProps {
  title: string;
  id: string;
  values: any;
  setFieldValue: any;
}

const BillsCard: FC<BillsCardProps> = ({ title, id, values, setFieldValue }) => {
  const [openedSection, setIsOpenSection] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [openSubSection, setOpenSubSection] = useState<number[]>([]);
  const [showBillAlertModal, setShowBillAlertModal] = useState(false);

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [openedSection, values?.[id]]);

  const subSectionHandler = (index: number) => {
    setOpenSubSection((prev: any) => {
      if (prev.includes(index)) {
        return prev.filter((i: number) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const deleteHandler = (index: number) => {
    const temp = values?.[id].filter((_: any, i: number) => i !== index);
    setFieldValue(id, temp);
  };

  const addMoreHandler = () => {
    const newUsers = [...(values?.[id] || []), { issuedBy: "", amount: "", date: "", billNumber: "" }];
    setFieldValue(id, newUsers);
  };

  const openSectionHandler = () => {
    if (id !== "hospitalMainBill" && values?.pharmacyBills.length === 0 && values?.preHospitalBill.length === 0 && values?.postHospitalBill.length === 0 && values?.otherExpense.length === 0) {
      setShowBillAlertModal(true);
    }
    setIsOpenSection(!openedSection);
  };

  return (
    <div className=" border-[1px] border-[#D8DDE9] rounded-xl bg-[#F5F8FD] overflow-hidden m-4 my-5 ">
      <div className="flex shadow-xl rounded-xl h-14 bg-white justify-between items-center px-6 py-2">
        <span className="flex gap-3">
          <img src={billLabel} alt="bill-icon" />
          <p className="font-extrabold">{title}</p>
        </span>
        <img src={arrowCircle} alt="arrow icon" className={`cursor-pointer transition-all duration-500 ${openedSection ? "rotate-0" : "rotate-180"}`} onClick={openSectionHandler} />
      </div>

      <div
        ref={contentRef}
        className={`transition-[height] ease-in-out duration-500 overflow-scroll`}
        style={{
          height: openedSection ? `${contentHeight}px` : "0px"
        }}
      >
        {values?.[id]?.length > 0 &&
          values?.[id].map((bill: any, index: number) => {
            if (hcrDetails?.[id] && !hcrDetails?.[id].some((hcrDetail: any) => hcrDetail?.issuedBy === bill.issuedBy && hcrDetail?.billNumber === bill?.billNumber)) return;
            return (
              <div key={index} className="relative pl-10 mt-7 py-5 rounded-xl bg-white shadow-lg">
                <div className="flex gap-12 ">
                  <p className="bg-[#D7E3FE] flex items-center justify-center h-5 w-5 absolute left-0 text-xs font-bold top-9">{index + 1}</p>
                  <div>
                    <p className="font-medium text-sm mb-1">Issued By</p>
                    <p className="font-bold">{bill?.issuedBy}</p>
                  </div>
                  <div>
                    <p className="font-medium text-sm mb-1">Bill Amount</p>
                    <p className="font-bold">{bill?.amount}</p>
                  </div>
                  <img src={arrow} alt="arrow" className={`cursor-pointer transition-all ${openSubSection.includes(index) ? "rotate-180" : "rotate-0"}`} onClick={() => subSectionHandler(index)} />
                </div>
                <div onClick={() => deleteHandler(index)} className="bg-[#FFAFAD4D] flex gap-2 items-center w-[4.5rem] px-2 py-[0.15rem] rounded absolute right-6 cursor-pointer top-9">
                  <img src={deleteIcon} alt="delete-icon" className="w-3 h-3" />
                  <p className="font-medium text-xs text-[#CB4844]">Delete</p>
                </div>
                {openSubSection.includes(index) && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 pr-8 py-8 bg-white">
                    {["preHospitalBill", "postHospitalBill"].includes(id) && (
                      <span className="h-full">
                        <label className="font-semibold text-sm" htmlFor={"Type of Admission *"}>
                          Type of Bill
                        </label>
                        <ReactSelect options={typeOfBillsArr} value={{ value: bill?.typeOfBill }} getOptionLabel={(option) => option?.value} onChange={(option: any) => setFieldValue(`[${id}][${index}].typeOfBill`, option.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
                      </span>
                    )}
                    {bill?.typeOfBill === "Other" && <Input label="Name of the other bill" name="billName" value={bill?.billName} onChange={(e) => setFieldValue(`[${id}][${index}].billNamec`, e.target.value)} />}
                    {id === "otherExpense" && <Textarea name="Description" label="Description" parentClassName="h-full pb-7" placeholder="Description" value={bill?.description} onChange={(e: any) => setFieldValue(`[${id}][${index}].description`, e.target.value)} />}
                    <Input label="Issued By" name="issuedBy" value={bill?.issuedBy} onChange={(e) => setFieldValue(`[${id}][${index}].issuedBy`, e.target.value)} />
                    <Input label="Bill Date" type="date" name="date" value={bill?.date} placeholder="DD/MM/YYYY" icon={<img src={calenderIcon} alt="calender-icon" />} onChange={(e) => setFieldValue(`[${id}][${index}].date`, e.target.value)} />
                    <Input label="Bill Number" name="billNumber" value={bill?.billNumber} onChange={(e) => setFieldValue(`[${id}][${index}].billNumber`, e.target.value)} />
                    <Input label="Bill Amount" type="number" name="amount" value={bill?.amount} onChange={(e) => setFieldValue(`[${id}][${index}].amount`, e.target.value)} />
                  </div>
                )}
              </div>
            );
          })}
        {values?.[id] &&
          values?.[id].map((bill: any, index: number) => {
            if (hcrDetails?.[id] && hcrDetails?.[id].some((hcrDetail: any) => hcrDetail?.issuedBy === bill.issuedBy && hcrDetail?.billNumber === bill?.billNumber)) return;
            return (
              <>
                {hcrDetails?.[id]?.length === index && (
                  <div className="border-t-[1px] border-dashed border-[#00000033] w-full mx-auto mb-2 mt-10 flex justify-center">
                    <div className="flex -m-2">
                      <p className="text-xs font-medium px-3 bg-[#D1D8E5] rounded-[4px] py-1">Add New Bill Details</p>
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2 px-6 py-8 bg-[#F5F8FD]" key={index}>
                  {["preHospitalBill", "postHospitalBill"].includes(id) && (
                    <span className="h-full">
                      <label className="font-semibold text-sm" htmlFor={"Type of Admission *"}>
                        Type of Bill
                      </label>
                      <ReactSelect options={typeOfBillsArr} value={{ value: bill?.typeOfBill }} getOptionLabel={(option) => option?.value} onChange={(option: any) => setFieldValue(`[${id}][${index}].typeOfBill`, option.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
                    </span>
                  )}

                  {bill?.typeOfBill === "Other" && <Input label="Name of the other bill" name="billName" value={bill?.billName} onChange={(e) => setFieldValue(`[${id}][${index}].billName`, e.target.value)} />}
                  {id === "otherExpense" && <Textarea name="Description" label="Description" parentClassName="h-full pb-7" placeholder="Description" value={bill?.description} onChange={(e: any) => setFieldValue(`[${id}][${index}].description`, e.target.value)} />}
                  <Input label="Issued By" name="issuedBy" value={bill?.issuedBy} onChange={(e) => setFieldValue(`[${id}][${index}].issuedBy`, e.target.value)} />
                  <Input label="Bill Date" type="date" name="date" value={bill?.date} placeholder="DD/MM/YYYY" icon={<img src={calenderIcon} alt="calender-icon" />} onChange={(e) => setFieldValue(`[${id}][${index}].date`, e.target.value)} />
                  <Input label="Bill Number" name="billNumber" value={bill?.billNumber} onChange={(e) => setFieldValue(`[${id}][${index}].billNumber`, e.target.value)} />
                  <Input label="Bill Amount" type="number" name="amount" value={bill?.amount} onChange={(e) => setFieldValue(`[${id}][${index}].amount`, e.target.value)} />
                </div>
              </>
            );
          })}

        <div className="flex justify-center my-5">
          <ButtonOutline title="Add More" onClick={addMoreHandler} />
        </div>
      </div>
      <BillAlert showModal={showBillAlertModal} onClose={() => setShowBillAlertModal(!showBillAlertModal)} />
    </div>
  );
};

export default BillsCard;
