import React, { FC, useEffect, useRef, useState } from "react";
import billLabel from "assets/icons/bill-icon.svg";
import arrowCircle from "assets/icons/arrow-circle.svg";
import Input from "components/Semantic/Input";
import Checkbox from "components/Semantic/Checkbox";

interface LumpSumProps {
  values: any;
  setFieldValue: any;
}

const LumpSumCard: FC<LumpSumProps> = ({ values, setFieldValue }) => {
  const [openedSection, setIsOpenSection] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [openedSection]);

  return (
    <div className=" border-[1px] border-[#D8DDE9] rounded-xl bg-[#F5F8FD] overflow-hidden m-4 my-5 ">
      <div className="flex shadow-xl rounded-xl h-14 bg-white justify-between items-center px-6 py-2">
        <span className="flex gap-3">
          <img src={billLabel} alt="bill-icon" />
          <p className="font-extrabold">Lump sum/Cash benefit</p>
        </span>
        <img src={arrowCircle} alt="arrow icon" className={`cursor-pointer transition-all duration-500 ${openedSection ? "rotate-0" : "rotate-180"}`} onClick={() => setIsOpenSection(!openedSection)} />
      </div>

      <div
        ref={contentRef}
        className={`transition-[height] ease-in-out duration-500 overflow-scroll`}
        style={{
          height: openedSection ? `${contentHeight}px` : "0px"
        }}
      >
        <div className="grid grid-cols-1 gap-x-3 gap-y-8 px-6 py-8 bg-[#F5F8FD]">
          {values?.lumpSumCashBenefit?.length > 0 &&
            values?.lumpSumCashBenefit?.map((obj: any, index: number) => {
              return (
                <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between gap-5 md:gap-10 w-full" key={index}>
                  <Checkbox label={obj.title} labelClassName="font-medium text-sm" checked={obj.isSelected} onChange={() => setFieldValue(`lumpSumCashBenefit[${index}].isSelected`, !obj.isSelected)} />
                  {obj.isSelected && obj.title === "Others" && <Input value={obj?.description} onChange={(e) => setFieldValue(`lumpSumCashBenefit[${index}].description`, e.target.value)} placeholder={`Description`} parentClassName="w-full max-w-72" />}
                  {obj.isSelected && <Input value={obj?.amount} type="number" onChange={(e) => setFieldValue(`lumpSumCashBenefit[${index}].amount`, e.target.value)} placeholder={`${obj.title}`} parentClassName="w-full max-w-72" />}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LumpSumCard;
