import React, { useEffect, useState } from "react";
import BorderCard from "components/common/BorderCard";
import SingleButtonFooter from "components/common/SingleButtonFooter";
import { typeOfProcessHCR, typeOfReimbursementValue } from "config/constants";
import ReactSelect from "react-select";
import Checkbox from "components/Semantic/Checkbox";
import DocListModal from "./components/modals/DocListModal";
import BottomContent from "components/common/BottomContent";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import { currentClaimFormDataThunk, updateFormV2Thunk } from "store/thunk/hcr.thunk";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const InitialScreen = () => {
  const hcrId: any = useParams();
  const dispatch = useAppDispatch();

  const [showDocModal, setShowDocModal] = useState(false);

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoading = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [dispatch, hcrId.id]);

  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      wasHealthClaimRejectedDuringCashless: hcrDetails?.wasHealthClaimRejectedDuringCashless?.filter((i: any) => i.isSelected) || null,
      typeOfReimbursement: hcrDetails?.typeOfReimbursement || null
    },
    onSubmit: (values) => {
      if (!validationChecker()) return;
      const payload = {
        _id: hcrId.id,
        step: 1,
        wasHealthClaimRejectedDuringCashless: values.wasHealthClaimRejectedDuringCashless[0]?.name,
        typeOfReimbursement: values.typeOfReimbursement.filter((item: any) => item.isSelected).map((item: any) => item.name)
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) setShowDocModal(true);
        });
    }
  });

  const validationChecker = () => {
    const bothSelected = values.typeOfReimbursement.some((obj: any) => obj.name === "Hospitalisation Expenses" && obj.isSelected) && values.typeOfReimbursement.some((obj: any) => obj.name === "Day Care Treatment Expense" && obj.isSelected);

    if (values.wasHealthClaimRejectedDuringCashless.length === 0) {
      toast.error("Please select the cashless process");
      return false;
    } else if (!values.typeOfReimbursement?.some((obj: any) => obj?.isSelected)) {
      toast.error("Please select atleast  one type of reimbursement");
      return false;
    } else if (bothSelected) {
      toast.error("You cannot select hospital expense and day care at the same time");
      return false;
    }
    return true;
  };

  const handleChangeManual = (name: string, options: any, field: string) => {
    setFieldValue(field, [options.find((option: any) => option?.name === name)]);
  };
  const handleCheckboxManual = (name: string, options: any[], field: string) => {
    setFieldValue(
      field,
      options.map((option: any) => {
        if (option.name === name) {
          return { ...option, isSelected: !option.isSelected };
        }
        return option;
      })
    );
  };

  const toggleDocModal = () => setShowDocModal((prev) => !prev);

  return (
    <>
      <p className="pl-5 pb-2 md:hidden block font-extrabold text-lg">Health Claim Reimbursement</p>
      <BorderCard className="w-[90%] md:w-[70%] lg:w-[50%]">
        <div className="pt-0 md:pt-10 pb-5 md:pb-12">
          <p className="font-extrabold hidden md:block text-2xl px-4 md:px-7">Health Claim Reimbursement</p>
          <div className="shadow-all my-0 md:my-10 py-10 px-4 md:px-7 space-y-2">
            <p className="font-medium">Was your health insurance claim rejected during the cashless process?</p>
            <ReactSelect value={values.wasHealthClaimRejectedDuringCashless && typeOfProcessHCR.find((option) => option.name === values.wasHealthClaimRejectedDuringCashless[0]?.name)} onChange={(option) => handleChangeManual(option.name, typeOfProcessHCR, "wasHealthClaimRejectedDuringCashless")} getOptionValue={(option) => option?.name} options={typeOfProcessHCR} className="w-full md:w-96" isSearchable={false} />
          </div>
          <div className="px-4 mt-8 md:m-0 md:px-7 grid grid-cols-1 md:grid-cols-2 gap-y-4">
            <p className="font-medium text-sm block md:hidden">Please select the type of reimbursement you are currently applying for</p>
            {typeOfReimbursementValue.map((obj, index) => (
              <Checkbox label={obj.name} onChange={() => handleCheckboxManual(obj.value, values.typeOfReimbursement, "typeOfReimbursement")} key={index} checked={values.typeOfReimbursement && values.typeOfReimbursement.find((option: any) => option?.name === obj.value && option?.isSelected)} />
            ))}
          </div>
        </div>
        <SingleButtonFooter onClick={handleSubmit} buttonTitle="NEXT" view="web" className={isLoading ? "opacity-50" : ""} isLoading={isLoading} />
        <DocListModal showModal={showDocModal} onClose={toggleDocModal} hcrId={hcrId?.id} />
      </BorderCard>
      <BottomContent>
        <SingleButtonFooter onClick={handleSubmit} buttonTitle="NEXT" view="mobile" className={isLoading ? "opacity-50" : ""} isLoading={isLoading} />
      </BottomContent>
    </>
  );
};

export default InitialScreen;
