import React, { useEffect } from "react";
import Input from "components/Semantic/Input";
import Textarea from "components/Semantic/Textarea";
import timeIcon from "assets/icons/time.png";
import calenderIcon from "assets/icons/calender.svg";
import { useNavigate, useParams } from "react-router-dom";
import HcrDetailsBox from "components/common/HcrDetailsBox";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { currentClaimFormDataThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import ReactSelect from "react-select";
import { causeOfInjuryArr, customStyles, hospitalisationDueTo, roomCategory, statusOfDischarge, typeOfAdmission } from "config/constants";
import dayjs from "dayjs";
import { hospitalisationSchema } from "config/schema";
import { toast } from "react-toastify";
import ToggleButton from "components/Semantic/Toogle";

const Hospitalisation = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoadingNext = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);
  const isLoadingSave = useAppSelector((state) => state.rootReducer.hcrSlice.isLoadingSave);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [hcrId.id]);

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched }: any = useFormik({
    enableReinitialize: true,
    validationSchema: hospitalisationSchema,
    initialValues: {
      hospitalName: hcrDetails?.hospitalName,
      typeOfAdmission: hcrDetails?.typeOfAdmission,
      diagnosisDischargeSummary: hcrDetails?.diagnosisDischargeSummary,
      roomCategoryOccupied: hcrDetails?.roomCategoryOccupied,
      hospitalisationDueTo: hcrDetails?.hospitalisationDueTo,
      dateOfAdmission: hcrDetails?.dateOfAdmission ? dayjs(hcrDetails?.dateOfAdmission).format("YYYY-MM-DD") : "",
      timeOfAdmission: hcrDetails?.timeOfAdmission,
      dateOfDisease: hcrDetails?.dateOfDisease ? dayjs(hcrDetails?.dateOfDisease).format("YYYY-MM-DD") : "",
      dateOfDelivery: hcrDetails?.dateOfDelivery ? dayjs(hcrDetails?.dateOfDelivery).format("YYYY-MM-DD") : "",
      timeOfDischarge: hcrDetails?.timeOfDischarge,
      dateOfDischarge: hcrDetails?.dateOfDischarge ? dayjs(hcrDetails?.dateOfDischarge).format("YYYY-MM-DD") : "",
      statusAtDischarge: hcrDetails?.statusAtDischarge,
      causeOfInjury: hcrDetails?.causeOfInjury,
      medicoLegalCase: hcrDetails?.medicoLegalCase,
      reportedToPolice: hcrDetails?.reportedToPolice,
      dateOfInjury: hcrDetails?.dateOfInjury ? dayjs(hcrDetails?.dateOfInjury).format("YYYY-MM-DD") : ""
    },
    onSubmit: (values) => {
      if (values.hospitalisationDueTo === "Injury" && isDateGreater(values.dateOfAdmission, values.dateOfInjury)) {
        toast.error("Date of Injury cannot be greater than date of admission");
        return;
      }
      if (isDateGreater(values.dateOfDischarge, values.dateOfAdmission)) {
        toast.error("Date of discharge cannot be greater than date of admission");
        return;
      }
      if (isDateGreater(values.dateOfDelivery, values.dateOfAdmission)) {
        toast.error("Date of admission cannot be greater than date of delivery");
        return;
      }

      const payload = {
        _id: hcrId.id,
        ...values,
        dateOfAdmission: values.dateOfAdmission ? dayjs(values.dateOfAdmission).toISOString() : "",
        dateOfDischarge: values.dateOfDischarge ? dayjs(values.dateOfDischarge).toISOString() : "",
        dateOfDisease: values.dateOfDisease ? dayjs(values.dateOfDisease).toISOString() : "",
        step: 2
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) navigate("/hcr/doc-checklist/" + data?.data?._id);
        });
    }
  });

  const saveProgressHandler = () => {
    const payload = {
      _id: hcrId.id,
      ...values,
      dateOfAdmission: values.dateOfAdmission ? dayjs(values.dateOfAdmission).toISOString() : "",
      dateOfDischarge: values.dateOfDischarge ? dayjs(values.dateOfDischarge).toISOString() : "",
      dateOfDisease: values.dateOfDisease ? dayjs(values.dateOfDisease).toISOString() : ""
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  const isDateGreater = (firstDate: string, secondDate: string) => {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);

    return date2 > date1;
  };

  return (
    <HcrDetailsBox progress={10} nextAction={handleSubmit} title="Details Of Hospitalisation" isLoadingNext={isLoadingNext} saveProgress={saveProgressHandler} isLoadingSave={isLoadingSave}>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-4 md:gap-8 px-4 md:px-5 pb-10">
        <Input label="Name of the Hospital *" onChange={handleChange} name="hospitalName" placeholder="Hospital Name" value={values.hospitalName} errors={errors} touched={touched} />
        <span className="h-full">
          <label className="font-semibold text-sm" htmlFor={"Type of Admission *"}>
            Type of Admission *
          </label>
          <ReactSelect options={typeOfAdmission} onChange={(option: any) => setFieldValue("typeOfAdmission", option.value)} value={typeOfAdmission.find((option) => values.typeOfAdmission === option?.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
          {touched?.typeOfAdmission && errors?.typeOfAdmission && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.typeOfAdmission || ""}</p>}
        </span>
        <span className="row-span-2 h-full">
          <Textarea name="diagnosisDischargeSummary" onChange={handleChange} parentClassName="h-full pb-7" label="Diagnosis as per Discharge Summary *" placeholder="Discharge Summary" value={values.diagnosisDischargeSummary} errors={errors} touched={touched} />
        </span>
        <span className="h-full">
          <label className="font-semibold text-sm" htmlFor={"roomCategoryOccupied"}>
            Room Category Occupied *
          </label>
          <ReactSelect options={roomCategory} onChange={(option: any) => setFieldValue("roomCategoryOccupied", option.value)} value={roomCategory.find((option) => values.roomCategoryOccupied === option?.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
          {touched?.roomCategoryOccupied && errors?.roomCategoryOccupied && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.roomCategoryOccupied || ""}</p>}
        </span>
        <span className="h-full">
          <label className="font-semibold text-sm" htmlFor={"hospitalisationDueTo"}>
            Hospitalisation due to *
          </label>
          <ReactSelect options={hospitalisationDueTo} onChange={(option: any) => setFieldValue("hospitalisationDueTo", option.value)} value={hospitalisationDueTo.find((option) => values.hospitalisationDueTo === option?.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
          {touched?.hospitalisationDueTo && errors?.hospitalisationDueTo && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.hospitalisationDueTo || ""}</p>}
        </span>
        {values.hospitalisationDueTo === "Illness" && <Input name="dateOfDisease" label="Date of disease first detected *" onChange={handleChange} placeholder="DD/MM/YYYY" type="date" icon={<img src={calenderIcon} alt="calender-icon" />} value={values.dateOfDisease} errors={errors} touched={touched} />}
        {values.hospitalisationDueTo === "Maternity" && <Input name="dateOfDelivery" label="Date of Delivery *" onChange={handleChange} placeholder="DD/MM/YYYY" type="date" icon={<img src={calenderIcon} alt="calender-icon" />} value={values.dateOfDelivery} errors={errors} touched={touched} />}
        {values.hospitalisationDueTo === "Injury" && (
          <>
            <Input name="dateOfInjury" label="Date of Injury *" max={true} onChange={handleChange} placeholder="DD/MM/YYYY" type="date" icon={<img src={calenderIcon} alt="calender-icon" />} value={values.dateOfInjury} errors={errors} touched={touched} />
            <span className="h-full">
              <label className="font-semibold text-sm" htmlFor={"hospitalisationDueTo"}>
                Cause of Injury*
              </label>
              <ReactSelect options={causeOfInjuryArr} getOptionLabel={(option) => option.value} onChange={(option: any) => setFieldValue("causeOfInjury", option.value)} value={values.causeOfInjury ? { value: values.causeOfInjury } : null} className="w-full mt-1" styles={customStyles} isSearchable={false} />
              {touched?.causeOfInjury && errors?.causeOfInjury && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.causeOfInjury || ""}</p>}
            </span>
            <div className="my-2 md:my-0">
              <span className="flex gap-3 justify-between px-2">
                <p className="font-semibold text-sm max-w-[75%]">Was this case recorded as a Medico Legal case by the hospital?</p>
                <ToggleButton onClick={() => setFieldValue("medicoLegalCase", !values.medicoLegalCase)} checked={values.medicoLegalCase} />
              </span>
              <span className="flex gap-3 justify-between px-2 mt-3">
                <p className="font-semibold text-sm max-w-[75%]">Was this case reported to Police?</p>
                <ToggleButton onClick={() => setFieldValue("reportedToPolice", !values.reportedToPolice)} checked={values.reportedToPolice} />
              </span>
            </div>
          </>
        )}
        <Input name="dateOfAdmission" max={true} label="Date of Admission" onChange={handleChange} placeholder="DD/MM/YYYY" type="date" icon={<img src={calenderIcon} alt="calender-icon" />} value={values.dateOfAdmission} errors={errors} touched={touched} />
        <Input name="timeOfAdmission" label="Time of Admission" onChange={handleChange} placeholder="DD/MM/YYYY" type="time" icon={<img src={timeIcon} className="w-4 mr-1" alt="time-icon" />} value={values.timeOfAdmission} errors={errors} touched={touched} />
        <Input name="dateOfDischarge" label="Date of Discharge *" onChange={handleChange} placeholder="DD/MM/YYYY" type="date" icon={<img src={calenderIcon} alt="calender-icon" />} value={values.dateOfDischarge} errors={errors} touched={touched} />
        <Input name="timeOfDischarge" label="Time of Discharge *" onChange={handleChange} placeholder="DD/MM/YYYY" type="time" icon={<img src={timeIcon} className="w-4 mr-1" alt="time-icon" />} value={values.timeOfDischarge} errors={errors} touched={touched} />
        <span className="h-full">
          <label className="font-semibold text-sm" htmlFor={"hospitalisationDueTo"}>
            Status at the time of Discharge *
          </label>

          <ReactSelect options={statusOfDischarge} menuPlacement="top" onChange={(option: any) => setFieldValue("statusAtDischarge", option.value)} value={statusOfDischarge.find((option) => values.statusAtDischarge === option?.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
          {touched?.statusAtDischarge && errors?.statusAtDischarge && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.statusAtDischarge || ""}</p>}
        </span>
      </div>
    </HcrDetailsBox>
  );
};

export default Hospitalisation;
