import HcrDetailsBox from "components/common/HcrDetailsBox";
import React, { useEffect, useState } from "react";
import BillsCard from "./components/billsCard";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { currentClaimFormDataThunk, lumpSumListThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import LumpSumCard from "./components/lumpSumCard";
import BillSum from "./components/modals/BillSum";

const ClaimBillsScreen = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showBillSumModal, setShowBillSumModal] = useState(false);

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const lumpSumList = useAppSelector((state) => state.rootReducer.hcrSlice.lumpSumList);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
    dispatch(lumpSumListThunk());
  }, [dispatch, hcrId.id]);

  const { values, setFieldValue, handleSubmit }: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      hospitalMainBill: hcrDetails?.hospitalMainBill?.length === 0 ? [{ issuedBy: "", amount: "", date: "", billNumber: "" }] : hcrDetails?.hospitalMainBill,
      pharmacyBills: hcrDetails?.pharmacyBills || [],
      preHospitalBill: hcrDetails?.preHospitalBill || [],
      postHospitalBill: hcrDetails?.postHospitalBill || [],
      otherExpense: hcrDetails?.otherExpense || [],
      lumpSumCashBenefit: hcrDetails?.lumpSumCashBenefit?.length === 0 ? lumpSumList : hcrDetails?.lumpSumCashBenefit
    },
    onSubmit: (values) => {
      if (!showBillSumModal) {
        setShowBillSumModal(true);
        return;
      }
      const payload = {
        ...values,
        _id: hcrId.id,
        step: 7
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          setShowBillSumModal(false);
          if (data?.success) navigate("/hcr/bank-details/" + hcrId?.id);
        });
    }
  });

  const saveProgressHandler = () => {
    const payload = {
      ...values,
      _id: hcrId.id,
      step: 7
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  return (
    <>
      <HcrDetailsBox progress={60} title="Details Of Claim/Bills" nextAction={handleSubmit} saveProgress={saveProgressHandler}>
        <BillsCard title="Hospital Main Bill" id="hospitalMainBill" values={values} setFieldValue={setFieldValue} />
        <BillsCard title="Pharmacy Bill" id="pharmacyBills" values={values} setFieldValue={setFieldValue} />
        <BillsCard title="Pre Hospital Bill" id="preHospitalBill" values={values} setFieldValue={setFieldValue} />
        <BillsCard title="Post Hospital Bill" id="postHospitalBill" values={values} setFieldValue={setFieldValue} />
        <BillsCard title="Other Expenses" id="otherExpense" values={values} setFieldValue={setFieldValue} />
        <LumpSumCard values={values} setFieldValue={setFieldValue} />
      </HcrDetailsBox>
      <BillSum showModal={showBillSumModal} onClose={() => setShowBillSumModal(!showBillSumModal)} billDetails={values} submitHandler={handleSubmit} />
    </>
  );
};

export default ClaimBillsScreen;
