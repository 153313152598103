import React, { FC } from "react";

interface ButtonProps {
  title: string;
  onClick: any;
  className?: string;
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({ title, onClick, className, isLoading }) => {
  return (
    <button className={`bg-polyfix-gradient h-12 rounded-lg min-w-44 md:min-w-52 font-bold flex items-center justify-center gap-3 ${isLoading && "opacity-50 pointer-events-none"} ${className}`} onClick={onClick}>
      {isLoading && <div className="w-4 h-4 border-[2px] border-t-[#dea15c]  rounded-full animate-spin"></div>}
      <p>{title}</p>
    </button>
  );
};

export default Button;
