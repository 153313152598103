import React, { useEffect } from "react";
import Input from "components/Semantic/Input";
import calenderIcon from "assets/icons/calender.svg";
import ToggleButton from "components/Semantic/Toogle";
import HcrDetailsBox from "components/common/HcrDetailsBox";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { getAllCompaniesThunk } from "store/thunk/main.thunk";
import ReactSelect from "react-select";
import { customStyles } from "config/constants";
import Textarea from "components/Semantic/Textarea";
import { useFormik } from "formik";
import { currentClaimFormDataThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const InsuranceHistory = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const companiesList = useAppSelector((state) => state.rootReducer.mainSlice.companyList);
  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoadingNext = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);
  const isLoadingSave = useAppSelector((state) => state.rootReducer.hcrSlice.isLoadingSave);

  useEffect(() => {
    dispatch(getAllCompaniesThunk());
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [hcrId]);

  const { values, setFieldValue, handleChange, handleSubmit, setFieldError, errors, touched }: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateOfCommencement: hcrDetails?.dateOfCommencement ? dayjs(hcrDetails?.dateOfCommencement).format("YYYY-MM-DD") : hcrDetails?.dateOfCommencement,
      coveredByMediHealthInsurance: hcrDetails?.coveredByMediHealthInsurance,
      coveredInsuranceCompanyName: hcrDetails?.coveredInsuranceCompanyName,
      coveredPolicyNumber: hcrDetails?.coveredPolicyNumber,
      coveredSumAssured: hcrDetails?.coveredSumAssured,
      prevCoveredMediHealthInsurance: hcrDetails?.prevCoveredMediHealthInsurance,
      prevInsuredCompanyName: hcrDetails?.prevInsuredCompanyName,
      hospitalisedSInceptionContract: hcrDetails?.hospitalisedSInceptionContract,
      inceptionContractHospitalisedDate: hcrDetails?.inceptionContractHospitalisedDate ? dayjs(hcrDetails?.inceptionContractHospitalisedDate).format("YYYY-MM-DD") : "",
      inceptionContractHospitalisedDiagnosis: hcrDetails?.inceptionContractHospitalisedDiagnosis
    },
    onSubmit: (values) => {
      if (!validationChecker()) return;
      const payload = {
        ...values,
        _id: hcrId.id,
        dateOfCommencement: values.dateOfCommencement ? dayjs(values.dateOfCommencement).toISOString() : "",
        inceptionContractHospitalisedDate: values.inceptionContractHospitalisedDate ? dayjs(values.inceptionContractHospitalisedDate).toISOString() : "",
        step: 6
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) navigate("/hcr/claim-bills/" + data?.data?._id);
        });
    }
  });

  const validationChecker = () => {
    let isValid = true;

    if (values.coveredByMediHealthInsurance) {
      if (!values.coveredInsuranceCompanyName) {
        setFieldError("coveredInsuranceCompanyName", "Please select insurance company name");
        isValid = false;
      }
      if (!values.coveredPolicyNumber.trim()) {
        setFieldError("coveredPolicyNumber", "Please enter policy number");
        isValid = false;
      }
      if (!values.coveredSumAssured || values.coveredSumAssured < 0) {
        setFieldError("coveredSumAssured", "Please enter valid sum assured");
        isValid = false;
      }
    }

    if (values.prevCoveredMediHealthInsurance && !values.prevInsuredCompanyName) {
      setFieldError("prevInsuredCompanyName", "Please select insurance company name");
      isValid = false;
    }

    if (values.hospitalisedSInceptionContract) {
      if (!values.inceptionContractHospitalisedDate) {
        setFieldError("inceptionContractHospitalisedDate", "Please enter date");
        isValid = false;
      }
      if (!values.inceptionContractHospitalisedDiagnosis.trim()) {
        setFieldError("inceptionContractHospitalisedDiagnosis", "Please enter details of diagnosis");
        isValid = false;
      }
    }

    return isValid;
  };

  const saveProgressHandler = () => {
    const payload = {
      _id: hcrId.id,
      ...(values.dateOfCommencement ? { patientDob: dayjs(values.dateOfCommencement).toISOString() } : ""),
      ...(values.inceptionContractHospitalisedDate ? { patientDob: dayjs(values.inceptionContractHospitalisedDate).toISOString() } : ""),
      ...values
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  return (
    <HcrDetailsBox progress={50} nextAction={handleSubmit} saveProgress={saveProgressHandler} title="Details Of Insurance History" isLoadingNext={isLoadingNext} isLoadingSave={isLoadingSave}>
      <div className="w-full">
        <div className="shadow-wide">
          <div className="w-full md:w-1/2 p-5">
            <Input max={true} label="Date of commencement of first insurance without break" name="dateOfCommencement" onChange={handleChange} value={values.dateOfCommencement} placeholder="Date" type="date" icon={<img src={calenderIcon} alt="calender-icon" />} />
          </div>
        </div>

        {/* First Toggle */}
        <div className="py-7  shadow-wide px-6">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium w-56 md:w-full">Currently covered by any other Mediclaim/Health Insurance?</p>
            <ToggleButton onClick={() => setFieldValue("coveredByMediHealthInsurance", !values.coveredByMediHealthInsurance)} checked={values.coveredByMediHealthInsurance} />
          </div>
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2 mt-4 px-2 pb-2 sliding-grid ${values.coveredByMediHealthInsurance ? "show" : ""}`}>
            <span>
              <p className="font-bold text-sm mb-2">Insurance Company *</p>
              <ReactSelect options={companiesList} menuPlacement="top" maxMenuHeight={260} onChange={(option) => setFieldValue("coveredInsuranceCompanyName", option.name)} getOptionLabel={(option) => option.name} value={companiesList.find((option) => option.name === values.coveredInsuranceCompanyName)} getOptionValue={(option: any) => option.name} styles={customStyles} placeholder="Insurance Company" className="w-full" />
              {touched?.coveredInsuranceCompanyName && errors?.coveredInsuranceCompanyName && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.coveredInsuranceCompanyName || ""}</p>}
            </span>
            <Input label="Policy Number *" name="coveredPolicyNumber" value={values.coveredPolicyNumber} onChange={handleChange} errors={errors} touched={touched} />
            <Input label="Sum Assured *" name="coveredSumAssured" type="number" value={values.coveredSumAssured} onChange={handleChange} errors={errors} touched={touched} />
          </div>
        </div>

        {/* Second Toggle */}
        <div className="py-7 shadow-wide px-6">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium w-56 md:w-full">Previously covered by any other Mediclaim/Health Insurance?</p>
            <ToggleButton onClick={() => setFieldValue("prevCoveredMediHealthInsurance", !values.prevCoveredMediHealthInsurance)} checked={values.prevCoveredMediHealthInsurance} />
          </div>
          <div className={` mt-4 px-2 pb-2 sliding-grid ${values?.prevCoveredMediHealthInsurance ? "show" : ""}`}>
            <span>
              <p className="font-bold text-sm mb-2">Insurance Company *</p>
              <ReactSelect options={companiesList} maxMenuHeight={250} onChange={(option) => setFieldValue("prevInsuredCompanyName", option.name)} menuPlacement="top" getOptionLabel={(option) => option.name} value={companiesList.find((option) => option.name === values.prevInsuredCompanyName)} getOptionValue={(option: any) => option.name} styles={customStyles} placeholder="Insurance Company" className="w-full" />
              {touched?.prevInsuredCompanyName && errors?.prevInsuredCompanyName && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.prevInsuredCompanyName || ""}</p>}
            </span>
          </div>
        </div>

        {/* Third Toggle */}
        <div className="py-7 shadow-wide px-6">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium w-56 md:w-full">Have you ever been hospitalized in the last 4 years since inception of the contract?</p>
            <ToggleButton onClick={() => setFieldValue("hospitalisedSInceptionContract", !values.hospitalisedSInceptionContract)} checked={values.hospitalisedSInceptionContract} />
          </div>
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-x-6 pb-2 mt-4 px-2 sliding-grid ${values?.hospitalisedSInceptionContract ? "show" : ""}`}>
            <Input label="Date*" type="date" max={true} icon={<img src={calenderIcon} alt="calender-icon" />} onChange={handleChange} value={values.inceptionContractHospitalisedDate} name="inceptionContractHospitalisedDate" errors={errors} touched={touched} />
            <Textarea parentClassName=" mb-10 pb-1" label="Diagnosis *" placeholder="Diagnosis" onChange={handleChange} value={values.inceptionContractHospitalisedDiagnosis} name="inceptionContractHospitalisedDiagnosis" errors={errors} touched={touched} />
          </div>
        </div>
      </div>
    </HcrDetailsBox>
  );
};

export default InsuranceHistory;
