import HcrDetailsBox from "components/common/HcrDetailsBox";
import Input from "components/Semantic/Input";
import RadioButton from "components/Semantic/RadioButton";
import Textarea from "components/Semantic/Textarea";
import { hospitalDetailsSchema } from "config/schema";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { currentClaimFormDataThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";

const HospitalDetails = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoadingNext = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);
  const isLoadingSave = useAppSelector((state) => state.rootReducer.hcrSlice.isLoadingSave);

  const { values, handleSubmit, handleChange, errors, touched, setFieldValue }: any = useFormik({
    enableReinitialize: true,
    validationSchema: hospitalDetailsSchema,
    initialValues: {
      nameOfHospital: hcrDetails?.nameOfHospital,
      typeOfHospital: hcrDetails?.typeOfHospital,
      hospitalAddress: hcrDetails?.hospitalAddress,
      hospitalPinCode: hcrDetails?.hospitalPinCode,
      hospitalState: hcrDetails?.hospitalState,
      hospitalCity: hcrDetails?.hospitalCity,
      hospitalPan: hcrDetails?.hospitalPan,
      inPatientBedCount: hcrDetails?.inPatientBedCount,
      facilitiesICU: hcrDetails?.facilitiesICU,
      facilitiesOT: hcrDetails?.facilitiesOT,
      nameOfTreatingDoc: hcrDetails?.nameOfTreatingDoc,
      regNumWithStateCode: hcrDetails?.regNumWithStateCode
    },
    onSubmit: (values) => {
      const payload = {
        _id: hcrId.id,
        ...values,

        step: 9
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) navigate("/hcr/disclaimer/" + hcrId?.id);
        });
    }
  });

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [dispatch, hcrId.id]);

  const saveProgressHandler = () => {
    const payload = {
      _id: hcrId.id,
      ...values,
      step: 9
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  return (
    <HcrDetailsBox progress={90} title="Hospital Details" nextAction={handleSubmit} isLoadingSave={isLoadingSave} isLoadingNext={isLoadingNext} saveProgress={saveProgressHandler}>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-6  gap-5 px-5 pb-10 max-h-[26rem] overflow-y-scroll ">
        <Input label="Name of the Hospital *" name="nameOfHospital" placeholder="Hospital Name" onChange={handleChange} value={values.nameOfHospital} errors={errors} touched={touched} />
        <div>
          <p className="font-semibold text-sm">Type Of Hospital *</p>
          <span className="flex flex-wrap gap-y-2 justify-between items-center mt-5">
            <RadioButton label="Network" name="Network" value={values.typeOfHospital} onChange={() => setFieldValue("typeOfHospital", "Network")} />
            <RadioButton label="Non-network" name="Non-network" value={values.typeOfHospital} onChange={() => setFieldValue("typeOfHospital", "Non-network")} />
            <RadioButton label="Not Sure" name="Not Sure" value={values.typeOfHospital} onChange={() => setFieldValue("typeOfHospital", "Not Sure")} />
          </span>
          {touched?.typeOfHospital && errors?.typeOfHospital && <p className=" text-red-600 font-semibold text-sm mt-2">{errors?.typeOfHospital}</p>}
        </div>
        {["Non-network", "Not Sure"].includes(values.typeOfHospital) && (
          <>
            <span className="row-span-2 h-full">
              <Textarea name="hospitalAddress" parentClassName="h-full pb-7" label="Address *" placeholder="Address Line" value={values.hospitalAddress} onChange={handleChange} errors={errors} touched={touched} />
            </span>
            <Input label="Pin Code *" name="hospitalPinCode" limit={6} placeholder="Pin Code" onChange={handleChange} value={values.hospitalPinCode} errors={errors} touched={touched} />
            <Input label="State *" name="hospitalState" placeholder="State" onChange={handleChange} value={values.hospitalState} errors={errors} touched={touched} />
            <Input label="City *" name="hospitalCity" placeholder="City" onChange={handleChange} value={values.hospitalCity} errors={errors} touched={touched} />
            <Input label="Hospital PAN" name="hospitalPan" placeholder="Hospital PAN" onChange={handleChange} value={values.hospitalPan} errors={errors} touched={touched} />
            <Input label="No of inpatient beds" name="inPatientBedCount" placeholder="No of inpatient beds" value={values.inPatientBedCount} onChange={handleChange} errors={errors} touched={touched} />
            <div>
              <p className="font-semibold text-sm mb-2">Facilities available in the hospital</p>
              <span className="flex gap-14 items-center">
                <p className="font-semibold text-sm">ICU</p>
                <RadioButton label="Yes" name="facilitiesICU" value={values.facilitiesICU ? "facilitiesICU" : ""} onChange={() => setFieldValue("facilitiesICU", true)} />
                <RadioButton label="No" name="facilitiesICU" value={values.facilitiesICU === false ? "facilitiesICU" : ""} onChange={() => setFieldValue("facilitiesICU", false)} />
              </span>
              <span className="flex gap-14 mt-3 items-center">
                <p className="font-semibold text-sm">OT</p>
                <RadioButton label="Yes" name="facilitiesOT" value={values.facilitiesOT ? "facilitiesOT" : ""} onChange={() => setFieldValue("facilitiesOT", true)} />
                <RadioButton label="No" name="facilitiesOT" value={values.facilitiesOT === false ? "facilitiesOT" : ""} onChange={() => setFieldValue("facilitiesOT", false)} />
              </span>
            </div>
          </>
        )}
        <Input label="Name of the Treating Doctor *" name="nameOfTreatingDoc" value={values.nameOfTreatingDoc} placeholder="Name of the Treating Doctor" onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Registration No. with State Code" name="regNumWithStateCode" value={values.regNumWithStateCode} placeholder="Registration No. with State Code" onChange={handleChange} errors={errors} touched={touched} />
      </div>
    </HcrDetailsBox>
  );
};

export default HospitalDetails;
