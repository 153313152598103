import React from "react";
import AppLayout from "components/layout/AppLayout";
import InitScreen from "pages/InitScreen/InitScreen.main";
import UploadPolicy from "pages/UploadPolicy/UploadPolicy.main";
import UploadDoc from "pages/UploadPolicy/UploadDoc.main";
import InitialScreen from "pages/hcr/InitialScreen";
import AllClaimForm from "pages/hcr/AllClaimForm";
import Hospitalisation from "pages/hcr/Hospitalisation.screen";
import DocChecklist from "pages/hcr/DocChecklist.screen";
import InsuranceHistory from "pages/hcr/InsuranceHistory";
import ClaimBillsScreen from "pages/hcr/ClaimBills.screen";
import OptScreen from "pages/InitScreen/OtpScreen.main";
import PolicyHolder from "pages/hcr/PolicyHolder.screen";
import NotFound from "pages/NotFound";
import PatientDetails from "pages/hcr/PatientDetails.screen";
import BankDetails from "pages/hcr/BankDetails.screen";
import HospitalDetails from "pages/hcr/HospitalDetails.screen";
import Disclaimer from "pages/hcr/Disclaimer.screen";
import ClaimForm from "pages/hcr/ClaimForm.screen";
import DownloadForm from "pages/hcr/DownloadForm.screen";

interface RouteType {
  path: string;
  element?: React.ReactNode;
  privateRoute: boolean;
  children?: RouteType[];
  index?: any;
}

const routes: RouteType[] = [
  {
    path: "/",
    privateRoute: false,
    children: [
      {
        index: true,
        path: "",
        element: (
          <AppLayout>
            <InitScreen />
          </AppLayout>
        ),
        privateRoute: false
      },
      {
        path: "otp",
        privateRoute: false,
        children: [
          {
            index: true,
            path: "",
            element: (
              <AppLayout>
                <OptScreen />
              </AppLayout>
            ),
            privateRoute: false
          }
        ]
      },
      {
        path: "upload-policy",
        privateRoute: false,
        children: [
          {
            index: true,
            path: "",
            element: (
              <AppLayout>
                <UploadPolicy />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            path: "docs/:pageType",
            element: (
              <AppLayout>
                <UploadDoc />
              </AppLayout>
            ),
            privateRoute: false
          }
        ]
      },
      {
        path: "hcr",
        privateRoute: false,
        children: [
          {
            index: true,
            path: "reimbursement/:id",
            element: (
              <AppLayout>
                <InitialScreen />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "all-claim-form",
            element: (
              <AppLayout>
                <AllClaimForm />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "hospitalisation-details/:id",
            element: (
              <AppLayout>
                <Hospitalisation />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "doc-checklist/:id",
            element: (
              <AppLayout>
                <DocChecklist />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "policyholder/:id",
            element: (
              <AppLayout>
                <PolicyHolder />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "patient/:id",
            element: (
              <AppLayout>
                <PatientDetails />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "insurance-history/:id",
            element: (
              <AppLayout>
                <InsuranceHistory />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "claim-bills/:id",
            element: (
              <AppLayout>
                <ClaimBillsScreen />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "bank-details/:id",
            element: (
              <AppLayout>
                <BankDetails />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "hospital-details/:id",
            element: (
              <AppLayout>
                <HospitalDetails />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "disclaimer/:id",
            element: (
              <AppLayout>
                <Disclaimer />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "claim-form/:id",
            element: (
              <AppLayout>
                <ClaimForm />
              </AppLayout>
            ),
            privateRoute: false
          },
          {
            index: true,
            path: "download-form/:id",
            element: (
              <AppLayout>
                <DownloadForm />
              </AppLayout>
            ),
            privateRoute: false
          }
        ]
      },
      {
        path: "*",
        element: (
          <AppLayout>
            <NotFound />
          </AppLayout>
        ),
        privateRoute: false
      }
    ]
  }
];

export default routes;
