import React, { FC } from "react";
import crossIcon from "assets/icons/cross.svg";
import tickIcon from "assets/icons/check.svg";

interface ToggleButtonProps {
  onClick: any;
  checked: boolean;
}

const ToggleButton: FC<ToggleButtonProps> = ({ onClick, checked }) => {
  return (
    <div className={`w-[2.7rem] h-6 flex items-center bg-gray-300 rounded-full p-1 pl-[0.15rem] cursor-pointer transition-all ${checked ? "bg-green-500" : ""}`} onClick={onClick}>
      <div className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform flex items-center justify-center ${checked ? "translate-x-5" : ""}`}>{checked ? <img src={tickIcon} className="filter invert" alt="tick icon" /> : <img src={crossIcon} alt="cross icon" />}</div>
    </div>
  );
};

export default ToggleButton;
