import React, { FC, ReactNode } from "react";
import BorderCard from "./BorderCard";
import ProgressBar from "./ProgressBar";
import DoubleButtonFooter from "./DoubleButtonFooter";

interface HcrDetailsBoxProps {
  children: ReactNode;
  progress: number;
  nextAction?: any;
  saveProgress?: any;
  title: string;
  isLoadingSave?: boolean;
  isLoadingNext?: boolean;
  hideSave?: boolean;
  hideProgress?: boolean;
  hideNext?: boolean;
  parentClassName?: string;
}

const HcrDetailsBox: FC<HcrDetailsBoxProps> = ({ children, progress, nextAction, title, isLoadingSave, isLoadingNext, hideSave = false, hideProgress = false, hideNext = false, saveProgress, parentClassName = "" }) => {
  return (
    <>
      <div className="fixed z-10 w-screen top-[3.7rem] md:hidden bg-white shadow-md pt-3 pb-5">
        <p className="font-extrabold   px-4">{title}</p>
        {!hideProgress && (
          <div className="flex items-center justify-center  px-5  mt-4 ">
            <ProgressBar percentage={progress} />
          </div>
        )}
      </div>
      <BorderCard className={`w-[90%] md:w-[70%] lg:w-[50%] mt-24 md:mt-0 mb-20 md:mb-0 ${parentClassName}`}>
        <div className="pt-0 md:pt-8 pb-5 md:pb-0  ">
          <p className="font-extrabold hidden md:block text-2xl px-4 md:px-7">{title}</p>
          {!hideProgress && (
            <div className=" hidden md:flex  justify-center py-5 shadow-all mt-4">
              <ProgressBar percentage={progress} />
            </div>
          )}
          {children}
          {!hideNext && <DoubleButtonFooter hideSave={hideSave} hideNext={hideNext} buttonTitle1="Save Progress" buttonTitle2={"NEXT"} onClick1={saveProgress} onClick2={nextAction} view="web" isLoadingNext={isLoadingNext} isLoadingSave={isLoadingSave} />}{" "}
        </div>
      </BorderCard>
      {!hideNext && <DoubleButtonFooter hideSave={hideSave} hideNext={hideNext} buttonTitle1="Save Progress" buttonTitle2="NEXT" onClick1={saveProgress} onClick2={nextAction} view="mobile" isLoadingNext={isLoadingNext} isLoadingSave={isLoadingSave} />}{" "}
    </>
  );
};

export default HcrDetailsBox;
