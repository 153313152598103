import * as Yup from "yup";

export const loginSchema = Yup.object({
  polHolderName: Yup.string()
    .min(2, "Name must be greater than 2 character")
    .max(25, "Name must be less than 25 character")
    .trim()
    .test("is-not-empty-or-space", "Name cannot be empty.", (value: any) => value && value.trim().length > 0)
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required!"),
  polHolderEmail: Yup.string()
    .trim()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Email is required!"),
  polHolderPhone: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Mobile number is required!")
});

export const hospitalisationSchema = Yup.object({
  hospitalName: Yup.string()
    .min(2, "Hospital Name must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Hospital Name cannot be empty.", (value: any) => value && value.trim().length > 0)
    .matches(/^[A-Za-z\s]+$/, "Hospital Name cannot contain numbers")
    .required("Hospital Name is required!"),
  diagnosisDischargeSummary: Yup.string()
    .min(2, "Discharge Summary must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Discharge Summary cannot be empty.", (value: any) => value && value.trim().length > 0)
    .matches(/^[A-Za-z\s]+$/, "Discharge Summary cannot contain numbers")
    .required("Discharge Summary is required!"),
  typeOfAdmission: Yup.string().required("Type of admission is required!"),
  roomCategoryOccupied: Yup.string().required("Room category is required!"),
  hospitalisationDueTo: Yup.string().required("Hospitalisation due to is required!"),
  dateOfDelivery: Yup.string().when("hospitalisationDueTo", {
    is: "Maternity",
    then: (schema) => schema.required("Date of delivery is required"),
    otherwise: (schema) => schema.notRequired()
  }),
  causeOfInjury: Yup.string().when("hospitalisationDueTo", {
    is: "Injury",
    then: (schema) => schema.required("Cause of injury is required"),
    otherwise: (schema) => schema.notRequired()
  }),
  timeOfDischarge: Yup.string().required("Time of dicharge is required!"),
  dateOfDischarge: Yup.string().required("Date of discharge is required!"),
  statusAtDischarge: Yup.string().required("Status at the time of discharge is required!")
});

export const policyHolderSchema = Yup.object({
  policyNumber: Yup.string()
    .min(2, "Policy Number must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Policy Number cannot be empty.", (value: any) => value && value.trim().length > 0)
    .required("Policy Number is required!"),
  policyHolderName: Yup.string()
    .min(2, "Policyholder name must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Policyholder name cannot be empty.", (value: any) => value && value.trim().length > 0)
    .matches(/^[A-Za-z\s]+$/, "Policyholder name cannot contain numbers")
    .required("Policyholder name is required!"),
  policyHolderPhone: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Policyholder phone is required!"),
  policyHolderEmail: Yup.string()
    .trim()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Policyholder email is required!"),
  policyHolderAddress: Yup.string()
    .min(2, "Policyholder address must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Policyholder address cannot be empty.", (value: any) => value && value.trim().length > 0)
    .required("Policyholder address is required!"),
  policyHolderPincode: Yup.string().required("Policyholder pincode is required!"),
  policyHolderState: Yup.string()
    .min(2, "Policyholder state must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Policyholder state cannot be empty.", (value: any) => value && value.trim().length > 0)
    .required("Policyholder state is required!"),
  policyHolderCity: Yup.string()
    .min(2, "Policyholder cty must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Policyholder city cannot be empty.", (value: any) => value && value.trim().length > 0)
    .required("Policyholder city is required!")
});

export const patientSchema = Yup.object({
  patientName: Yup.string()
    .min(2, "Patient name must be greater than 2 character")
    .max(25, "Patient name must be less than 25 character")
    .trim()
    .test("is-not-empty-or-space", "Patient name cannot be empty.", (value: any) => value && value.trim().length > 0)
    .matches(/^[A-Za-z\s]+$/, "Patient name cannot contain numbers")
    .required("Patient name is required!"),
  patientGender: Yup.string().required("Patient gender is required!"),
  patientDob: Yup.string().required("Patient Dob is required!"),
  patientPhoneNumber: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Patient phone is required!"),
  patientEmail: Yup.string()
    .trim()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Patient email is required!"),
  patientAddress: Yup.string()
    .min(2, "Patient address must be greater than 2 character")
    .max(25, "Patient address must be less than 25 character")
    .trim()
    .test("is-not-empty-or-space", "Patient address cannot be empty.", (value: any) => value && value.trim().length > 0)
    .required("Patient address is required!"),
  patientCity: Yup.string().required("Patient City is required!"),
  patientState: Yup.string().required("Patient State is required!"),
  patientPincode: Yup.string().required("Patient pincode is required!"),
  relationshipWithPrimaryInsured: Yup.string().required("Relationship with primary insured is required!"),
  occupation: Yup.string().required("Patient occupation is required!")
});

export const bankSchema = Yup.object({
  polHolderPanNumber: Yup.string()
    .matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/, "PAN must be exactly 10 characters.")
    .required("PAN is required!"),
  polHolderAccountNumber: Yup.string().trim().required("Account number is required!"),
  polHolderIfsc: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format")
    .required("IFSC code is required!"),
  polHolderBankName: Yup.string().trim().required("Bank Name is required!"),
  polHolderBranchName: Yup.string().trim().required("Branch Name is required!")
});

export const hospitalDetailsSchema = Yup.object({
  nameOfHospital: Yup.string()
    .min(2, "Hospital name must be greater than 2 character")
    .trim()
    .test("is-not-empty-or-space", "Hospital name cannot be empty.", (value: any) => value && value.trim().length > 0)
    .required("Hospital name is required!"),
  nameOfTreatingDoc: Yup.string().trim().required("Please enter name of the treating doctor"),
  typeOfHospital: Yup.string().required("Please select type of hospital"),
  hospitalAddress: Yup.string()
    .min(2, "Hospital address must be greater than 2 character")
    .trim()
    .when("typeOfHospital", {
      is: "Non-network" || "Not Sure",
      then: (schema) => schema.required("Hospital address is required"),
      otherwise: (schema) => schema.notRequired()
    }),
  hospitalCity: Yup.string().when("typeOfHospital", {
    is: "Non-network" || "Not Sure",
    then: (schema) => schema.required("Hospital city is required"),
    otherwise: (schema) => schema.notRequired()
  }),
  hospitalState: Yup.string().when("typeOfHospital", {
    is: "Non-network" || "Not Sure",
    then: (schema) => schema.required("Hospital state is required"),
    otherwise: (schema) => schema.notRequired()
  }),
  hospitalPinCode: Yup.string().when("typeOfHospital", {
    is: "Non-network" || "Not Sure",
    then: (schema) => schema.required("Hospital pincode is required"),
    otherwise: (schema) => schema.notRequired()
  }),
  hospitalPan: Yup.string().matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/, "Please Enter a valid pan")
});
