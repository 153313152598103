import HcrDetailsBox from "components/common/HcrDetailsBox";
import React, { useEffect, useState } from "react";
import { currentClaimFormDataThunk, getDisclaimerThunk, updateFormV2Thunk } from "store/thunk/hcr.thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import tickIcon from "assets/icons/disclaimer-tick.svg";
import Checkbox from "components/Semantic/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Disclaimer = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isChecked, setIsChecked] = useState(false);

  const disclaimerData = useAppSelector((state) => state.rootReducer.hcrSlice.disclaimerData);
  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoading = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);

  useEffect(() => {
    dispatch(getDisclaimerThunk());
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, []);

  const submitHandler = () => {
    if (!isChecked) {
      toast.error("Please tick the checkbox");
      return;
    }
    const payload = {
      _id: hcrDetails?._id,
      step: 10,
      disclaimerCheck: true
    };
    dispatch(updateFormV2Thunk(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) navigate("/hcr/claim-form/" + hcrId?.id);
      });
  };
  return (
    <HcrDetailsBox hideSave={true} progress={100} nextAction={submitHandler} title="Disclaimer" isLoadingNext={isLoading}>
      <div className="m-5 bg-[#F0F4FD] border-[1px] border-borderGray rounded-lg overflow-hidden">
        <header className="p-5 font-bold">{disclaimerData?.heading || "Disclaimer"}</header>
        <div className="bg-white rounded-t-3xl shadow-all p-5 space-y-4 pb-24 ">
          {disclaimerData &&
            disclaimerData?.pointers?.map((data: string, index: number) => {
              return (
                <div key={index} className="flex gap-4 items-start">
                  <img src={tickIcon} alt="tick-icon " className="mt-[3px]" />
                  <p className="font-medium text-sm leading-5">{data.slice(data.indexOf("I"))}</p>
                </div>
              );
            })}
        </div>
        <div className="bg-white p-5 shadow-top">
          <Checkbox label="I Agree and Accept." checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
        </div>
      </div>
    </HcrDetailsBox>
  );
};

export default Disclaimer;
