import React, { useEffect, useRef } from "react";
import BorderCard from "components/common/BorderCard";
import healthIcon from "assets/icons/health-icon.png";
import ReactSelect from "react-select";
import infoIcon from "assets/icons/info-icon.svg";
import imgIcon from "assets/icons/img-icon.svg";
import pdfIcon from "assets/icons/pdf-icon.svg";
import { useNavigate } from "react-router-dom";
import SingleButtonFooter from "components/common/SingleButtonFooter";
import { customStyles, policyTypesConstant } from "config/constants";
import { useAppDispatch, useAppSelector, useUserDetails } from "utils/hooks";
import { getAllCompaniesThunk } from "store/thunk/main.thunk";
import BottomContent from "components/common/BottomContent";
import { uploadPolicyDocThunk } from "store/thunk/hcr.thunk";
import { saveDocList, uploadFormValuesHandler } from "store/slice/hcr.slice";
import dayjs from "dayjs";
import { PDFDocument, rgb } from "pdf-lib";
import { toast } from "react-toastify";
import deleteIcon from "assets/icons/delete-icon-red.svg";

const UploadPolicy = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fileInputRef: any = useRef();
  const currentUserDetails = useUserDetails();
  const docList = useAppSelector((state) => state.rootReducer.hcrSlice.docList);
  const companiesList = useAppSelector((state) => state.rootReducer.mainSlice.companyList);
  const formValues = useAppSelector((state) => state.rootReducer.hcrSlice.uploadPolicyFormValues);
  const isLoading = useAppSelector((state) => state.rootReducer.hcrSlice.uploadDocLoading);

  useEffect(() => {
    dispatch(getAllCompaniesThunk());
  }, []);

  const submitHandler = async () => {
    const mergedPdf = await combinePdfHandler();

    const formData = new FormData();
    formData.append("typeOfPolicy", formValues.typeOfPolicy.value);
    formData.append("insuranceCompanyId", formValues.insuranceCompanyId._id);
    formData.append("owner", currentUserDetails._id);
    formData.append("phone", currentUserDetails.phone);
    formData.append("files", mergedPdf);

    const payload = {
      query: {
        userId: currentUserDetails?._id,
        name: "Self",
        phone: currentUserDetails?.phone,
        policyType: "Health"
      },
      body: formData
    };

    dispatch(uploadPolicyDocThunk(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) navigate("/hcr/reimbursement/" + data?.data?._id);
      });
  };
  const combinePdfHandler = async (isDownload?: boolean): Promise<Blob> => {
    const pdfDoc: any = await PDFDocument.create();

    for (const file of docList) {
      const fileType = file.name.split(".").pop().toLowerCase();

      if (fileType === "jpg" || fileType === "jpeg" || fileType === "png") {
        const imageData = await fileToDataURL(file);
        const imageBytes = await fetch(imageData).then((res) => res.arrayBuffer());

        let embeddedImage;

        if (fileType === "jpg" || fileType === "jpeg") {
          embeddedImage = await pdfDoc.embedJpg(imageBytes);
        } else if (fileType === "png") {
          embeddedImage = await pdfDoc.embedPng(imageBytes);
        }

        const imgPage = pdfDoc.addPage([embeddedImage.width, embeddedImage.height]);

        imgPage.drawRectangle({
          x: 0,
          y: 0,
          width: imgPage.getWidth(),
          height: imgPage.getHeight(),
          color: rgb(1, 1, 1)
        });

        imgPage.drawImage(embeddedImage, {
          x: 0,
          y: 0,
          width: embeddedImage.width,
          height: embeddedImage.height
        });
      } else if (fileType === "pdf") {
        const arrayBuffer = await file.arrayBuffer();
        const externalPdf = await PDFDocument.load(arrayBuffer);
        const pages: any = await pdfDoc.copyPages(externalPdf, externalPdf.getPageIndices());

        pages.forEach((page: any) => {
          pdfDoc.addPage(page);
        });
      }
    }

    const pdfBytes = await pdfDoc.save();

    if (isDownload) {
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "mergedPdf.pdf";
      link.click();
    }

    return new Blob([pdfBytes], { type: "application/pdf" });
  };

  const generateDynamicFileName = (): string => {
    const now = dayjs();

    const month = now.format("MMM");
    const day = now.format("DD");
    const year = now.format("YYYY");
    const time = now.format("HHmm");

    return `${month}${day}${year}${time}.pdf`;
  };

  const fileToDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const maxSize = 10 * 1024 * 1024;
    const maxFiles = 10;
    const exceededFiles = files.filter((file) => file.size > maxSize);

    if (exceededFiles.length > 0) {
      toast.error("One or more files exceed the 3MB limit.");
      return;
    }
    if (files.length > maxFiles) {
      toast.error(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }

    dispatch(saveDocList(files));
  };

  return (
    <>
      <BorderCard className="w-[90%] md:w-[70%] lg:w-[50%]">
        <div className="pt-0 md:pt-10  pb-5 md:pb-12 flex flex-col ">
          <p className="font-extrabold hidden md:block text-2xl px-4 md:px-7">Upload Your Policy</p>
          <div className="flex flex-col md:flex-row w-full mt-5 px-4 md:px-7">
            <div className="flex-[30%] flex flex-col items-start gap-2 justify-start">
              <p className="font-bold text-sm">Insurance Type</p>
              <img src={healthIcon} alt="health-icon" className="w-12 h-12" />
              <p className="font-medium text-xs text-center pl-1">Health</p>
            </div>
            <div className="flex-[70%] space-y-2 border-none md:border-l-[1px] pl-0 pt-4 md:pt-0  md:pl-7 border-[#00000033] h-48 flex flex-col items-start justify-between">
              <p className="font-bold text-sm">Insurance Company *</p>
              <ReactSelect value={formValues.insuranceCompanyId} onChange={(option: any) => dispatch(uploadFormValuesHandler({ name: "insuranceCompanyId", value: option }))} options={companiesList} getOptionLabel={(option) => option.name} getOptionValue={(option: any) => option._id} styles={customStyles} placeholder="Insurance Company" className="w-full" />
              <div className="flex gap-2 items-start">
                <img src={infoIcon} alt="info icon" className="mt-1" />
                <p className="text-xs text-[#000000B2] font-medium">Please note If you are unable to find your insurance company, it means that this feature will be available for your insurance company soon.</p>
              </div>
              <p className="font-bold text-sm">Policy Type *</p>
              <ReactSelect value={formValues.typeOfPolicy} onChange={(option: any) => dispatch(uploadFormValuesHandler({ name: "typeOfPolicy", value: option }))} options={policyTypesConstant} styles={customStyles} placeholder="Policy Type" isSearchable={false} className="w-full" />
            </div>
          </div>
          <div className="font-extrabold hidden md:block text-2xl bg-[#F9F9F9] my-8 py-5 px-8">Upload Policy</div>
          <div className="hidden md:flex gap-5 pl-8">
            <div>
              <div onClick={() => navigate("docs/image")} className="w-13 h-13 cursor-pointer  bg-white  p-4 rounded-md shadow-shadowAll ">
                <img src={imgIcon} alt="img-icon" />
              </div>
              <p className="font-medium text-xs text-center pl-1 pt-1">Image</p>
            </div>
            <div>
              <div onClick={() => fileInputRef.current?.click()} className="w-13 h-13 p-4 cursor-pointer bg-white rounded-md  shadow-shadowAll">
                <img src={pdfIcon} alt="img-icon" />
              </div>
              <p className="font-medium text-xs text-center pl-1 pt-1">PDF</p>
              <input type="file" multiple accept={".pdf"} ref={fileInputRef} onChange={handleFileChange} className="hidden" />
            </div>
            {docList.length > 0 && (
              <div>
                <p onClick={() => combinePdfHandler(true)} className="text-xs cursor-pointer items-center flex gap-4 bg-uploadTheme text-gray-600 rounded px-2 py-1 m-2">
                  <span>{generateDynamicFileName()}</span>
                  <img
                    src={deleteIcon}
                    alt="delete-icon"
                    className="w-5"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(saveDocList([]));
                    }}
                  />
                </p>
              </div>
            )}
          </div>
        </div>
        <SingleButtonFooter className={`${docList.length === 0 || isLoading || formValues.insuranceCompanyId?._id == "" || formValues.typeOfPolicy?.value == "" ? "opacity-50 pointer-events-none" : ""}`} isLoading={isLoading} onClick={submitHandler} buttonTitle="NEXT" view="web" />
      </BorderCard>

      <BorderCard className="w-[90%] md:w-[70%] lg:w-[50%] mt-5 block md:hidden overflow-hidden bg-[#F9F9F9]">
        <p className="py-5 px-5 font-extrabold">Upload Policy </p>
        <div className="bg-white shadow p-4">
          <div className="flex gap-4">
            <div>
              <div onClick={() => navigate("docs/image")} className="w-13 h-13 cursor-pointer  bg-white  p-4 rounded-md shadow-shadowAll ">
                <img src={imgIcon} alt="img-icon" />
              </div>
              <p className="font-medium text-xs text-center pl-1 pt-2">Image</p>
            </div>
            <div>
              <div onClick={() => fileInputRef.current?.click()} className="w-13 h-13 p-4 cursor-pointer bg-white rounded-md  shadow-shadowAll">
                <img src={pdfIcon} alt="img-icon" />
              </div>
              <p className="font-medium text-xs text-center pl-1 pt-2">PDF</p>
              <input type="file" multiple accept={".pdf"} ref={fileInputRef} onChange={handleFileChange} className="hidden" />
            </div>
            {docList.length > 0 && (
              <div>
                <p onClick={() => combinePdfHandler(true)} className="text-xs cursor-pointer items-center flex gap-4  bg-uploadTheme text-gray-600 rounded px-2 py-1 m-2">
                  <span>{generateDynamicFileName()}</span>
                  <img
                    src={deleteIcon}
                    alt="delete-icon"
                    className="w-5"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(saveDocList([]));
                    }}
                  />
                </p>
              </div>
            )}
          </div>
        </div>
      </BorderCard>

      <BottomContent>
        <SingleButtonFooter onClick={submitHandler} buttonTitle="NEXT" view="mobile" className={`${docList.length === 0 || isLoading || !formValues.insuranceCompanyId?._id || !formValues.typeOfPolicy?.value ? "opacity-50 pointer-events-none" : ""}`} isLoading={isLoading} />
      </BottomContent>
    </>
  );
};

export default UploadPolicy;
