import HcrDetailsBox from "components/common/HcrDetailsBox";
import Input from "components/Semantic/Input";
import React, { useEffect } from "react";
import calenderIcon from "assets/icons/calender.svg";
import ReactSelect from "react-select";
import { customStyles, genderArr, occupationArr, relationshipArr } from "config/constants";
import Divider from "components/common/Divider";
import Checkbox from "components/Semantic/Checkbox";
import Textarea from "components/Semantic/Textarea";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { currentClaimFormDataThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { patientSchema } from "config/schema";
import { toast } from "react-toastify";

const PatientDetails = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoadingNext = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);
  const isLoadingSave = useAppSelector((state) => state.rootReducer.hcrSlice.isLoadingSave);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [dispatch, hcrId.id]);

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched, setFieldError }: any = useFormik({
    enableReinitialize: true,
    validationSchema: patientSchema,
    initialValues: {
      patientName: hcrDetails?.patientName,
      patientGender: hcrDetails?.patientGender,
      patientDob: hcrDetails?.patientDob ? dayjs(hcrDetails?.patientDob).format("YYYY-MM-DD") : "",
      patientPhoneNumber: hcrDetails?.patientPhoneNumber,
      patientEmail: hcrDetails?.patientEmail,
      patientAddress: hcrDetails?.patientAddress,
      patientCity: hcrDetails?.patientCity,
      patientState: hcrDetails?.patientState,
      patientPincode: hcrDetails?.patientPincode,
      relationshipWithPrimaryInsured: hcrDetails?.relationshipWithPrimaryInsured,
      occupation: hcrDetails?.occupation,
      claimIntimationNumber: hcrDetails?.claimIntimationNumber,
      inPatientRegNo: hcrDetails?.inPatientRegNo,
      sameAddressAsPolicyholder: hcrDetails?.sameAddressAsPolicyholder,
      otherRelationshipWithPrimaryInsured: hcrDetails?.otherRelationshipWithPrimaryInsured || "",
      otherOccupation: hcrDetails?.otherOccupation || ""
    },
    onSubmit: (values) => {
      if (!validationChecker()) return;
      const payload = {
        ...values,
        _id: hcrId.id,
        step: 5,
        patientDob: dayjs(values.patientDob).toISOString()
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) navigate("/hcr/insurance-history/" + data?.data?._id);
        });
    }
  });

  useEffect(() => {
    if (values.sameAddressAsPolicyholder) {
      setFieldValue("patientAddress", hcrDetails.policyHolderAddress);
      setFieldValue("patientCity", hcrDetails.policyHolderCity);
      setFieldValue("patientState", hcrDetails.policyHolderState);
      setFieldValue("patientPincode", hcrDetails.policyHolderPincode);
    } else {
      setFieldValue("patientAddress", "");
      setFieldValue("patientCity", "");
      setFieldValue("patientState", "");
      setFieldValue("patientPincode", "");
    }
  }, [values.sameAddressAsPolicyholder]);

  const saveProgressHandler = () => {
    const payload = {
      _id: hcrId.id,
      ...(values.patientDob ? { patientDob: dayjs(values.patientDob).toISOString() } : ""),
      ...values
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  const validationChecker = () => {
    if (values.relationshipWithPrimaryInsured === "Others" && !values?.otherRelationshipWithPrimaryInsured?.trim()) {
      setFieldError("otherRelationshipWithPrimaryInsured", "Please enter other relation");
      return false;
    }
    if (values.occupation === "Others" && !values?.otherOccupation?.trim()) {
      setFieldError("otherOccupation", "Please enter other occupation");
      return false;
    }
    return true;
  };

  return (
    <HcrDetailsBox progress={40} nextAction={handleSubmit} saveProgress={saveProgressHandler} title="Details Of Patient" isLoadingNext={isLoadingNext} isLoadingSave={isLoadingSave}>
      <p className="font-bold text-[0.75rem] md:text-[0.9rem] px-5 pt-5 ">Please enter the Patient Details</p>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-8 px-4 md:px-5 pb-10 overflow-scroll ">
        <Input label="Patient Name *" name="patientName" placeholder="Patient Name" value={values.patientName} onChange={handleChange} errors={errors} touched={touched} />

        <span className="h-full">
          <label className="font-semibold text-sm" htmlFor={"Type of Admission *"}>
            Gender *
          </label>
          <ReactSelect options={genderArr} value={values.patientGender && { value: values.patientGender }} getOptionLabel={(option) => option?.value} onChange={(option: any) => setFieldValue("patientGender", option.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
          {touched?.patientGender && errors?.patientGender && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.patientGender || ""}</p>}
        </span>

        <Input name="patientDob" max={true} label="Date of Birth *" placeholder="DD/MM/YYYY" type="date" icon={<img src={calenderIcon} alt="calender-icon" />} value={values.patientDob} onChange={handleChange} errors={errors} touched={touched} />

        <span className="h-full">
          <label className="font-semibold text-sm" htmlFor={"Type of Admission *"}>
            Relationship with Primary Insured *
          </label>
          <ReactSelect options={relationshipArr} value={values.relationshipWithPrimaryInsured && { value: values.relationshipWithPrimaryInsured }} getOptionLabel={(option) => option.value} onChange={(option: any) => setFieldValue("relationshipWithPrimaryInsured", option.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
          {touched?.relationshipWithPrimaryInsured && errors?.relationshipWithPrimaryInsured && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.relationshipWithPrimaryInsured || ""}</p>}
        </span>

        {values.relationshipWithPrimaryInsured === "Others" && <Input label="Specify Relationship" name="otherRelationshipWithPrimaryInsured" placeholder="Enter relationship" value={values.otherRelationshipWithPrimaryInsured} onChange={handleChange} errors={errors} touched={touched} />}
        <Divider />

        <span className="h-full">
          <label className="font-semibold text-sm" htmlFor={"Type of Admission *"}>
            Occupation
          </label>
          <ReactSelect options={occupationArr} value={values.occupation && { value: values.occupation, label: values.occupation }} onChange={(option: any) => setFieldValue("occupation", option.value)} className="w-full mt-1" styles={customStyles} isSearchable={false} />
          {touched?.occupation && errors?.occupation && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.occupation || ""}</p>}
        </span>

        {values.occupation === "Others" && <Input label="Other Occupation" name="otherOccupation" placeholder="Other occupation" value={values.otherOccupation} onChange={handleChange} errors={errors} touched={touched} />}
        <Input name="patientEmail" label="Email ID *" placeholder="Email Id" type="email" value={values.patientEmail} onChange={handleChange} errors={errors} touched={touched} />
        <Input name="patientPhoneNumber" label="Phone Number" placeholder="Phone Number" type="number" limit={10} value={values.patientPhoneNumber} onChange={handleChange} errors={errors} touched={touched} />

        <span className="col-span-1 md:col-span-2">
          <Checkbox label="Address same as policyholder" checked={values.sameAddressAsPolicyholder} onChange={() => setFieldValue("sameAddressAsPolicyholder", !values.sameAddressAsPolicyholder)} />
        </span>

        <span className="row-span-2 h-full">
          <Textarea name="patientAddress" parentClassName="h-full pb-7" label="Address *" placeholder="Address Line" value={values.patientAddress} onChange={handleChange} errors={errors} touched={touched} />
        </span>

        <Input label="State *" name="patientState" placeholder="State" value={values.patientState} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="City *" name="patientCity" placeholder="City" value={values.patientCity} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Pincode *" name="patientPincode" type="number" limit={6} placeholder="Pin Code" value={values.patientPincode} onChange={handleChange} errors={errors} touched={touched} />

        <Divider />

        <Input label="Claim Intimation Number" name="claimIntimationNumber" placeholder="Claim Intimation Number" value={values.claimIntimationNumber} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="In-Patient Registered No." name="inPatientRegNo" placeholder="In-Patient Registered No." value={values.inPatientRegNo} onChange={handleChange} errors={errors} touched={touched} />
      </div>
    </HcrDetailsBox>
  );
};

export default PatientDetails;
