import React, { ChangeEvent } from "react";

export type InputProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  keyDownValidation?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onclick?: (event: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  limit?: number;
  parentClassName?: string;
  icon?: any;
  formProps: any;
};

const FormInput: React.FC<InputProps> = ({ onKeyDown, parentClassName, label, name = "", icon, onChange, type, limit, formProps, ...rest }) => {
  const { handleChange, handleBlur, values, touched, errors } = formProps;

  const inputChangeHandler = (e: any) => {
    if (limit && e.target.value.length > limit) return;
    if (handleChange && typeof handleChange === "function") {
      handleChange(e);
    }
  };

  return (
    <div className={parentClassName}>
      {label && (
        <label className="font-semibold text-sm" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="relative mt-1">
        <input {...rest} value={values?.[name]} onKeyDown={onKeyDown} onBlur={handleBlur} onChange={onChange || inputChangeHandler} type={type} name={name} className=" appearance-none border focus:ring-2 focus:outline-none focus:ring-black rounded w-full py-3 pl-5 px-3 text-gray-700 leading-tight focus:shadow-outline" />
        {icon && <div className="absolute inset-y-0 right-0 pr-[0.8rem] flex items-center pointer-events-none">{icon}</div>}
      </div>
      {touched?.[name] && errors?.[name] && <p className=" text-red-600 font-semibold text-sm pt-1 pl-5 ">{errors?.[name]}</p>}
    </div>
  );
};

export default FormInput;
