import BorderCard from "components/common/BorderCard";
import BottomContent from "components/common/BottomContent";
import SingleButtonFooter from "components/common/SingleButtonFooter";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserThunk, verifyOtpThunk } from "store/thunk/main.thunk";
import { useAppDispatch, useUserDetails } from "utils/hooks";

const OtpScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserDetails = useUserDetails();

  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [timer, setTimer] = useState(30);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (!currentUserDetails) navigate("/");
  }, []);

  const handleChange = (value: string, index: number) => {
    if (!isNaN(Number(value))) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !otp[index] && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = () => {
    const payload = {
      polHolderEmail: currentUserDetails.email,
      polHolderName: currentUserDetails.name,
      polHolderPhone: currentUserDetails.phone,
      otp: otp.join("")
    };
    dispatch(verifyOtpThunk(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          navigate("/hcr/all-claim-form");
        }
      });
  };

  const resendHandler = async () => {
    const payload = {
      polHolderEmail: currentUserDetails.email,
      polHolderName: currentUserDetails.name,
      polHolderPhone: currentUserDetails.phone
    };
    dispatch(createUserThunk(payload));
    setTimer(30);
    setOtp(new Array(6).fill(""));
  };

  return (
    <>
      <BorderCard className="w-[90%] md:w-[70%] lg:w-[30%]">
        <div className="pt-0 md:pt-10 px-4 md:px-7 pb-5 md:pb-12  flex justify-center flex-col items-center gap-y-1">
          <p className="font-extrabold mt-4 md:mt-0 text-2xl">OTP Verification</p>
          <p className="mt-3">Please enter the 6 digit code sent to</p>
          <p className="font-bold">{currentUserDetails ? "+91-" + currentUserDetails.phone : "-"}</p>
          <div className="space-x-1 md:space-x-3 mt-6">
            {otp.map((_, index) => (
              <input key={index} type="tel" placeholder="*" maxLength={1} value={otp[index]} onChange={(e) => handleChange(e.target.value, index)} onKeyDown={(e) => handleKeyDown(e, index)} ref={(el) => (inputRefs.current[index] = el)} className="w-12 h-14 border-[1px] rounded-md font-bold border-gray-300 text-center text-2xl focus:outline-none focus:border-black focus:border-[1.3px] leading-[3.5rem]" />
            ))}
          </div>
          <p className="text-[#000000B2] mt-6">
            Didn’t receive code?{" "}
            {timer === 0 ? (
              <span className="text-[#ED8C21] font-bold text-sm cursor-pointer" onClick={resendHandler}>
                Resend Code
              </span>
            ) : (
              <span className="text-[#FF8B84]">{` Resend in ${timer} seconds`}</span>
            )}
          </p>
        </div>

        <SingleButtonFooter onClick={submitHandler} buttonTitle="NEXT" view="web" />
      </BorderCard>
      <BottomContent>
        <SingleButtonFooter onClick={submitHandler} buttonTitle="NEXT" view="mobile" />
      </BottomContent>
    </>
  );
};

export default OtpScreen;
