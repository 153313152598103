import React, { useRef, useEffect, FC, ReactNode, useState } from "react";
import closeIcon from "assets/icons/close-icon.svg";

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  className?: string;
  children: ReactNode;
  style?: any;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, className, children, style }) => {
  const modalRef: any = useRef();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [isOpen]);

  if (!isOpen && !isAnimating) return null;

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-brightness-75 transition-opacity duration-300 ${isAnimating ? "opacity-100" : "opacity-0"}`} style={{ pointerEvents: isAnimating ? "auto" : "none" }}>
      <div style={style} ref={modalRef} className={`bg-white mx-4 md:mx-0 rounded-lg shadow-lg  transform transition-transform duration-300 ${isAnimating ? "scale-100" : "scale-95"} ${className}`}>
        <header className="flex flex-row-reverse my-5 px-5">
          <img src={closeIcon} alt="close icon" className="cursor-pointer" onClick={onClose} />
        </header>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
