import React, { useRef, useState } from "react";
import uploadImage from "assets/images/upload-doc.svg";
import downloadIcon from "assets/icons/download-icon.svg";
import BorderCard from "components/common/BorderCard";
import infoIcon from "assets/icons/info-icon.svg";
import ImageCard from "./components/ImageCard.component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import cameraIcon from "assets/icons/camera-icon.svg";
import galleryIcon from "assets/icons/gallery-icon.svg";
import { toast } from "react-toastify";
import Button from "components/Semantic/Button";
import { useAppDispatch } from "utils/hooks";
import { saveDocList } from "store/slice/hcr.slice";
import { useNavigate, useParams } from "react-router-dom";

const UploadDoc = () => {
  const { pageType } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const maxSize = 10 * 1024 * 1024;
    const maxFiles = 10;
    const exceededFiles = files.filter((file) => file.size > maxSize);

    if (exceededFiles.length > 0) {
      toast.error("One or more files exceed the 3MB limit.");
      return;
    }
    if (files.length > maxFiles || selectedDocs.length === maxFiles) {
      toast.error(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }

    setSelectedDocs((prevDocs: any) => [...prevDocs, ...files]);
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedDocs = Array.from(selectedDocs);
    const updatedPreviews = Array.from(imagePreviews);

    const [movedDoc] = updatedDocs.splice(result.source.index, 1);
    updatedDocs.splice(result.destination.index, 0, movedDoc);

    const [movedPreview] = updatedPreviews.splice(result.source.index, 1);
    updatedPreviews.splice(result.destination.index, 0, movedPreview);

    setSelectedDocs(updatedDocs);
    setImagePreviews(updatedPreviews);
  };

  const handleRemoveImage = (index: number) => {
    const updatedDocs = [...selectedDocs];
    const updatedPreviews = [...imagePreviews];

    URL.revokeObjectURL(updatedPreviews[index]);
    updatedDocs.splice(index, 1);
    updatedPreviews.splice(index, 1);

    setSelectedDocs(updatedDocs);
    setImagePreviews(updatedPreviews);
  };

  const submitHandler = () => {
    dispatch(saveDocList(selectedDocs));
    navigate("/upload-policy");
  };
  const handleCapture = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl: any = URL.createObjectURL(file);
      setImagePreviews((prevPreviews) => [...prevPreviews, imageUrl]);
      setSelectedDocs((prevDocs: any) => [...prevDocs, file]);
    }
  };

  return (
    <>
      {selectedDocs.length === 0 ? (
        <div className="w-screen flex mt-20 flex-col gap-6 items-center justify-center">
          <div className="bg-[#F0F4FD] rounded-[10px] p-7">
            <img src={uploadImage} alt="upload-doc-image" />
          </div>
          <p className="font-medium text-center px-10">Upload images of your policy document to Preview and Submit</p>
          <button onClick={() => fileInputRef.current?.click()} className="bg-[#CEEADD] hidden md:flex mt-5 rounded-[6.75rem] gap-3  justify-center items-center w-56 py-3">
            <img src={downloadIcon} alt="download-icon" />
            <p className="font-bold">Upload</p>
            <input type="file" multiple accept={pageType === "image" ? ".jpg,.jpeg,.png" : ".pdf"} ref={fileInputRef} onChange={handleFileChange} className="hidden" />
          </button>
        </div>
      ) : (
        <>
          <div className="flex md:hidden gap-4 px-5 mb-5">
            <p className="font-medium text-[13px]">Click on submit button to upload the document.</p>
            <Button title={"Submit"} onClick={submitHandler} className="block md:hidden min-w-36" />
          </div>
          <BorderCard className="w-[90%] md:w-[70%] pb-20 lg:w-[46.5%]  h-[35rem] relative overflow-scroll">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="image-cards">
                {(provided) => (
                  <div className="flex gap-3 flex-wrap pl-5 pt-5" ref={provided.innerRef} {...provided.droppableProps}>
                    {imagePreviews.map((img, index) => (
                      <Draggable key={index} draggableId={`image-${index}`} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <ImageCard count={index + 1} imgSrc={img} removeImg={() => handleRemoveImage(index)} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <footer className="flex absolute bottom-0 w-full items-center gap-3 h-14 bg-[#F0F4FD] py-6 px-8">
              <img src={infoIcon} alt="info-icon" />
              <p>Press and hold to drag and drop the image to change sequence</p>
            </footer>
          </BorderCard>
          <div className="flex justify-center gap-4 items-center  mt-5">
            <div className="hidden md:flex justify-center">
              <button onClick={() => fileInputRef.current?.click()} className="bg-[#CEEADD] rounded-[6.75rem] gap-3 flex justify-center items-center w-56 py-3">
                <img src={downloadIcon} alt="download-icon" />
                <p className="font-bold">Upload</p>
                <input type="file" multiple accept={pageType === "image" ? ".jpg,.jpeg,.png" : ".pdf"} ref={fileInputRef} onChange={handleFileChange} className="hidden" />
              </button>
            </div>
            <Button title={"Submit"} onClick={submitHandler} className="hidden md:block" />
          </div>
        </>
      )}

      <nav className="fixed block md:hidden bottom-0 right-0 left-0 h-20  bg-white shadow-polifyx  lg:pt-4">
        <div className="flex justify-center items-center h-full gap-[1px] ">
          {pageType === "image" && (
            <label htmlFor="cameraInput" className="w-1/2 flex flex-col items-center justify-center bg-[#DAE4FA] py-4">
              <img src={cameraIcon} />
              <p>Camera</p>
              <input type="file" accept="image/*" capture="environment" id="cameraInput" className="hidden" onChange={handleCapture} />
            </label>
          )}
          <div onClick={() => fileInputRef.current?.click()} className={`${pageType === "image" ? "w-1/2" : "w-full"} flex flex-col items-center  justify-center bg-[#CEEADD]  py-4`}>
            <img src={galleryIcon} />
            <p>Photo Library</p>
          </div>
        </div>
      </nav>
    </>
  );
};

export default UploadDoc;
