import React, { FC, ReactNode } from "react";

interface BorderCardProps {
  children: ReactNode;
  className?: string;
  parentClassName?: string;
}

const BorderCard: FC<BorderCardProps> = ({ children, className, parentClassName = "" }) => {
  return (
    <div className={`flex w-full justify-center ${parentClassName}`}>
      <div className={`${className} border-[1px] border-borderGray rounded-lg`}>{children}</div>
    </div>
  );
};

export default BorderCard;
