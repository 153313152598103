import React, { FC } from "react";
import tick from "assets/icons/check.svg";

interface CheckboxProps {
  label: string;
  labelClassName?: string;
  checkboxClassName?: string;
  checked?: boolean;
  onChange?: any;
}
const Checkbox: FC<CheckboxProps> = ({ label, labelClassName, checked, onChange, checkboxClassName }) => {
  return (
    <div className="flex items-center">
      <label className="relative inline-flex  gap-3 items-center cursor-pointer">
        <input type="checkbox" checked={checked} className="sr-only peer" onChange={onChange} />
        <div className={`w-6 h-6 rounded  bg-gradient-to-br ${checked && "from-yellow-400 to-orange-400"} flex items-center  justify-center transition-all duration-300 ${checked ? "bg-yellow-400 shadow-md" : "bg-white border-[1px] border-gray-400"} ${checkboxClassName}`}>{checked && <img src={tick} alt="tick" />}</div>
        <p className={labelClassName}>{label}</p>
      </label>
    </div>
  );
};

export default Checkbox;
