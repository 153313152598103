const env = process.env.REACT_APP_ENV || "dev";

let apiUrl;
switch (env) {
  case "dev":
    apiUrl = "https://api.dev.insurancesamadhan.com/";
    break;
  case "stage":
    apiUrl = "https://api.stage.insurancesamadhan.com/";
    break;
  case "prod":
    apiUrl = "https://api.insurancesamadhan.com/";
    break;
  default:
    apiUrl = "http://localhost:3999/";
}

export const baseUrl = apiUrl;
