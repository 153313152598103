import { combineReducers } from "@reduxjs/toolkit";
import mainSlice from "./slice/main.slice";
import hcrSlice from "./slice/hcr.slice";

const rootReducer = combineReducers({
  mainSlice,
  hcrSlice
});

export default rootReducer;
