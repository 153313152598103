import { FormikTouched, FormikValues } from "formik";
import React, { ChangeEvent } from "react";

export type InputProps = {
  label?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  type?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  keyDownValidation?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onclick?: (event: React.MouseEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  limit?: number;
  parentClassName?: string;
  icon?: any;
  errors?: any;
  touched?: FormikTouched<FormikValues>;
  handleBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  max?: boolean;
};

const Input: React.FC<InputProps> = ({ onKeyDown, parentClassName, label, name = "", icon, value, onChange, type, limit, touched, errors, max, ...rest }) => {
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <div className={parentClassName}>
      {label && (
        <label className="font-semibold text-sm" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="relative mt-1">
        <input
          {...rest}
          max={max ? currentDate : undefined}
          value={value}
          onKeyDown={onKeyDown}
          onChange={(e: any) => {
            if (limit && e.target.value.length > limit) return;
            if (onChange && typeof onChange === "function") {
              onChange(e);
            }
          }}
          type={type}
          name={name}
          className=" appearance-none border focus:ring-2 focus:outline-none focus:ring-black rounded w-full py-3 pl-5 px-3 min-h-12 bg-white text-gray-700 leading-tight focus:shadow-outline"
        />
        {icon && <div className="absolute inset-y-0 right-0 pr-[0.8rem] flex items-center pointer-events-none">{icon}</div>}
      </div>
      {touched?.[name] && errors?.[name] && <p className=" text-red-600 font-semibold text-sm mt-1">{errors?.[name]}</p>}
    </div>
  );
};

export default Input;
