import DoubleButtonFooter from "components/common/DoubleButtonFooter";
import Modal from "components/common/Modal";
import Button from "components/Semantic/Button";
import ButtonOutline from "components/Semantic/ButtonOutline";
import React, { FC } from "react";
import { useAppSelector } from "utils/hooks";

interface BillSumModalProps {
  showModal: boolean;
  onClose: any;
  billDetails: any;
  submitHandler: any;
}

const BillSum: FC<BillSumModalProps> = ({ showModal, onClose, billDetails, submitHandler }) => {
  const isLoading = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);

  const billSumHandler = () => {
    let totalLum = 0;
    let totalExpense = 0;

    const sumAmounts = (items: any[]) => {
      return items.reduce((sum, item) => sum + Number(item?.amount || "0"), 0);
    };

    if (billDetails?.lumpSumCashBenefit?.length) {
      totalLum = sumAmounts(billDetails.lumpSumCashBenefit);
    }
    const expenseTypes = ["hospitalMainBill", "preHospitalBill", "postHospitalBill", "pharmacyBills", "otherExpense"];

    expenseTypes.forEach((type) => {
      if (billDetails?.[type]?.length) {
        totalExpense += sumAmounts(billDetails[type]);
      }
    });

    return totalLum + totalExpense;
  };

  return (
    <Modal isOpen={showModal} onClose={onClose} className="w-[30rem] bg-white h-64 md:h-auto" style={{ boxShadow: "0px 8px 32px 0px #493E8320" }}>
      <p className="p-5 font-medium" style={{ boxShadow: "0px 8px 32px 0px #493E8320" }}>
        You are applying for a total claim amount of Rs. <span className="font-bold">{billSumHandler() || 0}</span>. In case of any changes required click on Go Back, else press Next{" "}
      </p>
      <DoubleButtonFooter hideNext={false} hideSave={false} buttonTitle1="Go Back" buttonTitle2={"NEXT"} onClick1={onClose} onClick2={submitHandler} view="web" isLoadingNext={isLoading} />

      {/* For Mobile View had to do this */}
      <div className={`fixed md:relative bottom-0 z-10 bg-white  w-full rounded-t-lg md:rounded-t-none rounded-b-lg py-3   border-t-[1px]  shadow-top overflow-hidden border-borderGray flex md:hidden`}>
        <div className="flex justify-center items-center gap-5 w-full h-16 md:h-20 bg-white">
          <ButtonOutline title={"Go Back"} onClick={onClose} className="min-w-32" />
          <Button title={"NEXT"} onClick={submitHandler} isLoading={isLoading} className="min-w-32" />
        </div>
      </div>
    </Modal>
  );
};

export default BillSum;
