import React, { FC } from "react";
import deleteIcon from "assets/icons/delete-icon.svg";

interface ImageCardProps {
  count: number;
  imgSrc: string;
  removeImg: any;
}

const ImageCard: FC<ImageCardProps> = ({ count, imgSrc, removeImg }) => {
  return (
    <div className="h-40 md:h-60 w-40 md:w-60 relative overflow-hidden  rounded ">
      <div className="w-10 text-sm h-10 left-1 top-1 absolute bg-white rounded-full flex justify-center items-center font-bold">{count}</div>
      <img src={imgSrc} alt="image card" className="w-56 h-56 rounded object-center" />
      <div onClick={removeImg} className="absolute right-1 bottom-1 bg-[#F5DADA] flex items-center justify-center cursor-pointer w-10 h-10 rounded-full">
        <img src={deleteIcon} alt="delete-icon" className="w-[0.8rem] " />
      </div>
    </div>
  );
};

export default ImageCard;
