import HcrDetailsBox from "components/common/HcrDetailsBox";
import Input from "components/Semantic/Input";
import { bankSchema } from "config/schema";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { currentClaimFormDataThunk, ifscBankDetailsThunk, updateFormV2Thunk, updateFormV2ThunkForSave } from "store/thunk/hcr.thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";

const BankDetails = () => {
  const hcrId: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const hcrDetails = useAppSelector((state) => state.rootReducer.hcrSlice.currentClaimForm);
  const isLoadingNext = useAppSelector((state) => state.rootReducer.hcrSlice.updateFormLoading);
  const isLoadingSave = useAppSelector((state) => state.rootReducer.hcrSlice.isLoadingSave);

  useEffect(() => {
    dispatch(currentClaimFormDataThunk(hcrId.id));
  }, [hcrId]);

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: bankSchema,
    initialValues: {
      polHolderPanNumber: hcrDetails?.polHolderPanNumber,
      polHolderAccountNumber: hcrDetails?.polHolderAccountNumber,
      polHolderIfsc: hcrDetails?.polHolderIfsc,
      polHolderBankName: hcrDetails?.polHolderBankName,
      polHolderBranchName: hcrDetails?.polHolderBranchName
    },
    onSubmit: (values) => {
      const payload = {
        _id: hcrId.id,
        step: 8,
        ...values
      };
      dispatch(updateFormV2Thunk(payload))
        .unwrap()
        .then((data) => {
          if (data?.success) navigate("/hcr/hospital-details/" + hcrId?.id);
        });
    }
  });

  useEffect(() => {
    if (values.polHolderIfsc && values.polHolderIfsc.length === 11) {
      dispatch(ifscBankDetailsThunk({ bankIfsc: values.polHolderIfsc }))
        .unwrap()
        .then((data) => {
          if (data?.status === 200) {
            setFieldValue("polHolderBankName", data?.data?.BANK);
            setFieldValue("polHolderBranchName", data?.data?.BRANCH);
          }
        });
    }
  }, [values.polHolderIfsc]);

  const saveProgressHandler = () => {
    const payload = {
      _id: hcrId.id,
      ...values
    };
    dispatch(updateFormV2ThunkForSave(payload))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success("Details saved successfully");
        }
      });
  };

  return (
    <HcrDetailsBox progress={75} nextAction={handleSubmit} saveProgress={saveProgressHandler} title="Bank Account Details" isLoadingNext={isLoadingNext} isLoadingSave={isLoadingSave}>
      <p className="font-bold text-[0.75rem] md:text-[0.9rem] px-5 pt-5 ">Details of Policyholder’s Bank Account</p>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-8 px-4 md:px-5 pb-10 overflow-scroll ">
        <Input label="Pan Number *" name="polHolderPanNumber" placeholder="Pan Number" value={values.polHolderPanNumber} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Account Number *" name="polHolderAccountNumber" placeholder="Account Number" value={values.polHolderAccountNumber} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Bank IFSC Code *" name="polHolderIfsc" placeholder="Bank IFSC Code" value={values.polHolderIfsc} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Bank Name *" name="polHolderBankName" placeholder="Bank Name" value={values.polHolderBankName} onChange={handleChange} errors={errors} touched={touched} />
        <Input label="Branch Name *" name="polHolderBranchName" placeholder="Branch Name" value={values.polHolderBranchName} onChange={handleChange} errors={errors} touched={touched} />
      </div>
    </HcrDetailsBox>
  );
};

export default BankDetails;
