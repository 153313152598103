import React, { FC } from "react";
import healthIcon from "assets/icons/claim-form.svg";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { screensList } from "config/constants";

interface ClaimCardProds {
  isLoading: boolean;
  claimData: any;
  pending: boolean;
}

const ClaimCard: FC<ClaimCardProds> = ({ isLoading, claimData, pending }) => {
  const navigation = useNavigate();

  const navigationHandler = () => {
    if (pending) {
      const nextScreen: any = screensList.find((screens: any) => screens.screenStep === claimData.step);
      if (!nextScreen) navigation("/hcr/reimbursement/" + claimData._id);
      else navigation("/hcr" + nextScreen?.route + "/" + claimData._id);
    } else {
      navigation("/hcr/download-form/" + claimData._id);
    }
  };

  return (
    <div className="bg-[#edf8f3] w-[23rem] border-[1px] shadow overflow-hidden border-[#CEEADD] rounded-xl">
      <header className="flex gap-4 px-5 py-2 items-center">
        <span className="flex flex-col gap-1 justify-center items-center">
          <img src={healthIcon} alt="health icon" className="w-5 h-5" />
          <p className="text-xs font-medium">Health</p>
        </span>
        {!isLoading ? <p className="font-bold">{claimData?.policyId?.insuranceCompanyId?.name}</p> : <div className="h-4 w-[87%] bg-gray-400  rounded-full dark:bg-gray-700  animate-pulse"></div>}
      </header>
      <div className="bg-[#f3fef9] shadow-all py-5 px-4 rounded-t-3xl font-medium text-xs">
        <span className="border-b-[1px] mb-2 border-[#00000033] border-dotted flex justify-between">
          <p>Patient Name :</p>
          {!isLoading ? <p>{claimData?.patientName || ""}</p> : <div className="h-3 w-[40%] bg-gray-400 rounded-full dark:bg-gray-700  animate-pulse"></div>}
        </span>
        <span className="border-b-[1px] mb-2 border-[#00000033] border-dotted flex justify-between">
          <p>Policy No :</p>

          {!isLoading ? <p>{claimData?.policyNumber || ""}</p> : <div className="h-3 w-[40%] bg-gray-400 rounded-full dark:bg-gray-700  animate-pulse"></div>}
        </span>
        <span className="border-b-[1px] mb-2 border-[#00000033] border-dotted flex justify-between">
          <p>Date of Hospitalization :</p>

          {!isLoading ? <p>{claimData?.dateOfAdmission ? dayjs(claimData?.dateOfAdmission).format("DD/MM/YYYY") : ""}</p> : <div className="h-3 w-[40%] bg-gray-400 rounded-full dark:bg-gray-700  animate-pulse"></div>}
        </span>
        <span className=" mb-2 flex justify-between">
          <p>Diagnosis :</p>

          {!isLoading ? <p>{claimData?.diagnosisDischargeSummary || ""}</p> : <div className="h-3 w-[40%] bg-gray-400 rounded-full dark:bg-gray-700  animate-pulse"></div>}
        </span>
        <span className="flex justify-center">
          <button onClick={navigationHandler} className={`rounded-3xl border-[1px] border-black px-3 font-bold py-1 bg-white ${isLoading && "opacity-50 pointer-events-none"}`}>
            {pending ? "CONTINUE" : "VIEW CLAIM FORM"}
          </button>
        </span>
      </div>
    </div>
  );
};

export default ClaimCard;
