import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import allRoutes from "routes/routes";
import { getUserDetails } from "store/thunk/hcr.thunk";
import { useAppDispatch } from "utils/hooks";

function App() {
  const dispatch = useAppDispatch();
  const routes = createBrowserRouter(allRoutes);

  const successClassName = "bg-[#D5F4E5] text-[#143F2A] font-bold w-[23rem] md:w-[27rem] py-2 px-2 my-2  rounded-md absolute flex  right-0 border border-[#95E3BE] ";
  const errorClassName = "bg-[#E57D66] text-white font-bold w-[23rem] md:w-[27rem] py-2 px-2 my-2  rounded-md absolute flex right-0 border border-[#E39E95] ";
  const warningClassName = "bg-yellow-500 text-white font-bold w-[23rem] md:w-[27rem] py-2 px-2 my-2  rounded-md absolute flex right-0 border border-yellow-600 ";

  useEffect(() => {
    if (sessionStorage.getItem("userToken")) dispatch(getUserDetails());
  }, []);

  return (
    <>
      <RouterProvider router={routes} />
      <ToastContainer
        hideProgressBar={true}
        icon={false}
        containerId={1}
        bodyStyle={{ height: "1.7rem" }}
        closeButton={
          <div className="flex items-center text-2xl cursor-pointer" onClick={() => toast.dismiss()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
              <line x1="0" y1="0" x2="24" y2="24" stroke="white" strokeWidth="4" />
              <line x1="24" y1="0" x2="0" y2="24" stroke="white" strokeWidth="4" />
            </svg>
          </div>
        }
        // eslint-disable-next-line react/prop-types
        toastClassName={(props: any) => (props?.type === "success" ? successClassName : props?.type === "warning" ? warningClassName : errorClassName)}
      />
    </>
  );
}

export default App;
